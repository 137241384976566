import React, { useState, useEffect } from "react";
import "./affiliate-dashboard.css";
import DataTable from "react-data-table-component";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/constant";

const columns = [
  {
    name: "Description",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Amount($)",
    selector: (row) => row.amount,
    sortable: true,
  },
  {
    name: "Received On",
    selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
    sortable: true,
  },
  {
    name: "Enrolled User",
    selector: (row) => row.sourceUser.name + ", " + row.sourceUser.email,
    sortable: true,
  },
];

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
      },
      fontSize: "17px",
      color: "#004aad",
    },
  },

  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
      },
      fontSize: "16px",
    },
  },
};

const CommissionTable = (props) => {
  const [tabledata, setTabledata] = useState([]);

  const setData = () => {
    setTimeout(() => {
      setTabledata(props.users);
    }, 500);
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <div className="chart_partts">
      <h2>My Commission Report</h2>
      <DataTable
        columns={columns}
        data={props.commissionReport}
        //pagination
        striped
        responsive
        customStyles={customStyles}
        subHeaderAlign="right"
        subHeaderWrap
        //paginationDefaultPage={page}
        pagination
      />
    </div>
  );
};
export default CommissionTable;
