import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { payments } from "../../actions/payment.actions";
import { getPrice } from "../../axios/payment.axios";
import { NotificationManager } from "react-notifications";

const UpdatePlanLoveTravels = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const setProduct = async (event) => {
    event.preventDefault();
    let price_id = event.target.dataset.price;
    getPrice(price_id)
      .then((response) => {
        let name = event.target.dataset.name;
        let product_id = event.target.dataset.name;

        let product = {
          product_id,
          name,
          price: response.data,
          price_id,
          initialPrice: response.data,
          priceObject: response.metadata,
        };
        //   console.log(product,"product")
        dispatch(payments.setProduct(product));
        if (props?.email) {
          history.push(`/update-plan-payment?email=${props?.email}`);
        } else {
          history.push(`/update-subscription`);
        }
      })
      .catch((err) => {
        NotificationManager.error("Something went wrong");
      });
  };

  return (
    <div className="monthly_price">
      <div className="price_inner">
        <div className="price_inner_inner">
          <div className="price_inner_heading">
            <h2>Monthly</h2>
            <h3>$97</h3>
          </div>
          <div className="price_button">
            {props.currentPackage === 97 ? (
              <>
                <button type="button" className="get_started_but">
                  Current Package
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="get_started_but"
                  onClick={(event) => {
                    setProduct(event);
                  }}
                  data-price={props.products.tvi[0].price}
                  data-product_id={props.products.tvi[0].id}
                  data-name={props.products.tvi[0].name}
                >
                  Get Plan
                </button>
              </>
            )}
            {/* <button type="button" className="get_started_but" onClick={(event) => { setProduct(event) }} data-price={props.products.tvi[0].price} data-product_id={props.products.tvi[0].id} data-name={props.products.tvi[0].name}>
                            Get Started
                        </button> */}
          </div>
        </div>
      </div>
      <div className="price_inner">
        <div className="price_inner_inner">
          <div className="price_inner_heading">
            <h2>3 Months</h2>
            <h3>$257</h3>
            <p>Save 12%</p>
          </div>
          <div className="price_button">
            {props.currentPackage === 257 ? (
              <>
                <button type="button" className="get_started_but">
                  Current Package
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="get_started_but"
                  onClick={(event) => {
                    setProduct(event);
                  }}
                  data-price={props.products.tvi[1].price}
                  data-product_id={props.products.tvi[1].id}
                  data-name={props.products.tvi[1].name}
                >
                  Get Plan
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="price_inner">
        <div className="price_inner_inner">
          <div className="price_inner_heading">
            <h2>6 Months</h2>
            <h3>$497</h3>
            <p>Save 15%</p>
          </div>
          <div className="price_button">
            {props.currentPackage === 497 ? (
              <>
                <button type="button" className="get_started_but">
                  Current Package
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="get_started_but"
                  onClick={(event) => {
                    setProduct(event);
                  }}
                  data-price={props.products.tvi[2].price}
                  data-product_id={props.products.tvi[2].id}
                  data-name={props.products.tvi[2].name}
                >
                  Get Plan
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="price_inner">
        <div className="price_inner_inner">
          <div className="price_inner_heading">
            <h2>Annual</h2>
            <h3>$947</h3>
            <p>Save 19%</p>
          </div>
          <div className="price_button">
            {props.currentPackage === 947 ? (
              <>
                <button type="button" className="get_started_but">
                  Current Package
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="get_started_but"
                  onClick={(event) => {
                    setProduct(event);
                  }}
                  data-price={props.products.tvi[3].price}
                  data-product_id={props.products.tvi[3].id}
                  data-name={props.products.tvi[3].name}
                >
                  Get Plan
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* ONLY FOR STAR MEMBERS */}

      {props.isStarBenifit && (
        <>
          <div className="price_inner">
            <div className="price_inner_inner">
              <div className="price_inner_heading">
                <h2>TVI Star</h2>
                <h3>$1,997</h3>
                <p>Yearly</p>
              </div>
              <div className="price_button">
                {props.currentPackage === 1997 ? (
                  <>
                    <button type="button" className="get_started_but">
                      Current Package
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="get_started_but"
                      onClick={(event) => {
                        setProduct(event);
                      }}
                      data-price={props.products.tvi[5].price}
                      data-product_id={props.products.tvi[5].id}
                      data-name={props.products.tvi[5].name}
                    >
                      Get Plan
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="price_inner">
            <div className="price_inner_inner">
              <div className="price_inner_heading">
                <h2>TVI Star Experience</h2>
                <h3>$4,997</h3>
                <p>Yearly</p>
              </div>
              <div className="price_button">
                {props.currentPackage === 4997 ? (
                  <>
                    <button type="button" className="get_started_but">
                      Current Package
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="get_started_but"
                      onClick={(event) => {
                        setProduct(event);
                      }}
                      data-price={props.products.tvi[6].price}
                      data-product_id={props.products.tvi[6].id}
                      data-name={props.products.tvi[6].name}
                    >
                      Get Plan
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UpdatePlanLoveTravels;
