import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Tab, Tabs, Modal } from "react-bootstrap";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "./my-account.css";
import countryConfig from "../../../config/country.config";
import axios from "../../../axios/axios";
import { message } from "antd";
import useFormValidation from "../../../hooks/useFormValidator";
import { products } from "../../../products/products.details";
import { useSelector, useDispatch } from "react-redux";
import { setSuccessCode } from "../../../actions/signUp.actions";
import DataTable from "react-data-table-component";
import moment from "moment";
import UpdateHirePlan from "../../signup/UpdateHirePlan";
import UpdatePlanLoveTravels from "../../signup/update-plan-love-travels";
import { useLocation } from 'react-router-dom';

const MyAccount = () => {
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  const starParam = queryParams.get('star');
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  // CHANGE PASSWORD
  const [new_pass, setNewPass] = useState("");
  const [con_pass, setConPass] = useState("");
  const [changePassError, setChangePasswordError] = useState("");

  const [loading, setLoading] = useState(false);

  const [saveCards, setSaveCards] = useState([]);

  // ADD NEW CARD
  const [card_holder_name, setCardHolderName] = useState("");
  const [card_number, setCardNumber] = useState("");
  const [exp_month, setExpMonth] = useState("");
  const [exp_year, setExpYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [add_new_card, setNewCard] = useState(false);

  const [tabkey, setTabKey] = useState("profile");
  const [paypalId, setpaypalId] = useState("");
  const [commissionType, setcommissionType] = useState("");
  const [commissionTypeWiseSection, setcommissionTypeWiseSection] =
    useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [modalshow, setmodalshow] = useState(false);
  const [hitData, setHitData] = useState(false);
  const [modalshowUpdate, setmodalshowUpdate] = useState(false);
  const [modalPlan, setmodalPlan] = useState(false);

  const [validationReport, validate] = useFormValidation();

  const successModal = useSelector((state) => state.changeStep.successCode);
  const dispatch = useDispatch();
  const [allTransaction, setAllTransaction] = useState([]);
  const [allLastFourDigitCard, setAllLastFourDigitCard] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState({
    totalPage: 1,
    totalRows: 1,
  });

  useEffect(() => {
    let data = localStorage.getItem("userDetails");
    if (data) {
      data = JSON.parse(data);
      getUserDetails(data.id);
    }
  }, []);

  const getUserDetails = (id) => {
    setLoading(true);

    axios
      .get(`/user/${id}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          const { data } = res.data;
          setUser(data);
          //getCustomerProductDetails(data.email)
          setName(data.name);
          setEmail(data.email);
          setPhone(data.phone);
          setAddress(data.address);
          setCity(data.city);
          setCountry(data.country);
          setState(data.state);
          setZipCode(data.zipCode);
          setpaypalId(data.paypalId);
          if (data.paypalId !== "") {
            setcommissionType("PayPal");
            setcommissionTypeWiseSection(true);
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleModalClose = () => {
    setmodalshow(false);
  };

  const handleModalCloseSuccess = () => {
    dispatch(setSuccessCode(false));
  };

  const handleModalCloseUpdate = () => {
    setmodalshowUpdate(false);
  };

  const handlePlanModalClose = () => {
    setmodalPlan(false);
  };
  const logout = async () => {                     
    localStorage.clear();
    message.warning("You are being logged out. Please wait...");
    window.open("/logout", "_self");
  };

  const handleCancelPlan = () => {
    setmodalshow(false);
    setLoading(true);
    axios
      .get(
        "/user/cancel-subscription/" +
          currentPlan.stripe_customer_id +
          "/tvi/" +
          currentPlan.id
      )
      .then((res) => {
        setLoading(false);
        message.success(res.data.message);
        setHitData(true);
        logout();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        message.error(err.message);
        setHitData(false);
      });
  };

  const changeTabKey = (key) => {
    if (key === "payment") {
      getAllCards(user);
      getAllTransaction(user, page, limit);
    }
    if (key === "planSubcription") {
      getCurrentPlan(user);
    }
    setTabKey(key);
  };

  const getCurrentPlan = (details) => {
    let { email, stripe_customer_id, isGlobalAidUser } = details;

    if (isGlobalAidUser === false && stripe_customer_id !== "") {
      setLoading(true);
      axios
        .get("/user/get-customer-current-plan/" + email)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            setCurrentPlan(res.data);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const getAllCards = (details) => {
    let { source, stripe_customer_id } = details;

    setLoading(true);

    const formData = {
      source,
      stripe_customer_id,
    };

    axios
      .post("/user/get-user-all-cards-new", formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          let lastFourDigit = [];
          if(res.data.cards.length > 0){
            for (let i = 0; i < res.data.cards.length; i++) {
              lastFourDigit.push(res.data.cards[i].card.last4);
            }
            setAllLastFourDigitCard(lastFourDigit);
          }
          setSaveCards(res.data.cards);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const updateUserDetails = (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      phone,
      address,
      city,
      country,
      state,
      zipCode,
      id: user.id,
    };
    setLoading(true);
    axios
      .post("/user/update-user-details", formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          message.success("Update Successfully.");
          localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const updatePassword = (e) => {
    e.preventDefault();

    if (new_pass !== con_pass) {
      setChangePasswordError("Confirm password not match");
      return;
    } else {
      setChangePasswordError("");
    }

    let formData = {
      id: user.id,
      email,
      password: new_pass,
    };
    setLoading(true);
    axios
      .post("/user/update-user-password", formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setNewPass("");
          setConPass("");
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const updatePaypalId = (e) => {
    e.preventDefault();

    let formData = {
      id: user.id,
      email,
      paypalId: paypalId,
      commissionType: commissionType,
    };
    setLoading(true);
    axios
      .post("/user/update-user-paypalid", formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          message.success("Update Successfully.");
          localStorage.setItem("userDetails", JSON.stringify(res.data.data));
          setpaypalId(paypalId);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const addNewCard = (e) => {
    e.preventDefault();

    for (const [, value] of Object.entries(validationReport)) {
      if (!value.isValid) return;
    }

    const old_card = saveCards[0]?.id || null;

    const formData = {
      number: card_number,
      exp_month,
      exp_year,
      cvc: cvv,
      id: user.stripe_customer_id,
      old_card,
    };

    if (allLastFourDigitCard.includes(card_number.slice(-4))) {
      console.log('object',card_number.slice(-4));
      message.error("Card already exists");
      return;
    }

    


    setLoading(true);

    axios
      .post("/add-new-card-new", formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          message.success("Card added Successfully");
          setNewCard(false);
          getAllCards(user);
        } else {
          message.error(res.data.message || res.data.error);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        message.error("Something Went Wrong!!!");
      });
  };

  const emptyField = () => {
    setCardHolderName("");
    setCardNumber("");
    setExpMonth("");
    setExpYear("");
    setExpYear("");
    setCvv("");
    setChangePasswordError("");
    setNewPass("");
    setConPass("");
  };

  const onChangeHandler = (e) => {
    let { name, value } = e.target;

    let rules = JSON.parse(e.target.dataset.rules);

    validate(value, rules, name);
  };

  const cancelCurrentPlan = () => {
    setmodalshow(true);
  };

  const updatePlan = () => {
    //history.push("/update-plan")
    setmodalshowUpdate(false);
    setmodalPlan(true);
  };

  const updatePlanModal = () => {
    setmodalshowUpdate(true);
  };

  const columns = [
    {
      name: "Type",
      selector: (row) => row.paymentType,
      width: "250px",
    },
    {
      name: "Amount",
      selector: (row) => "$" + row.amount,
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("MM-DD-YYYY, hh:mm a"),
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "150px",
    },
    {
      name: "Receipt",
      selector: (row) => (
        <>
          <a href={row.receipt_url} target="_blank" rel="noreferrer">
            Download
          </a>
        </>
      ),
      width: "150px",
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
        },
      },
    },
    cells: {
      style: {
        fontSize: "16px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
        },
      },
    },
  };

  const getAllTransaction = (details, page, limit) => {
    let { email } = details;
    setLoading(true);
    axios
      .get(
        `/user/get-all-transaction?page=${page}&limit=${limit}&email=${email}`
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllTransaction(res.data.data);
          setPagination(res.data.pagination);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setPage(page);
    getAllTransaction(user, page, limit);
  };
  const handlePerRowsChange = (row) => {
    setLimit(row);
    getAllTransaction(user, page, row);
  };

  useEffect(() => {
      if (starParam == 1) {
        changeTabKey('planSubcription');
        setmodalPlan(true);
      }
    }, [starParam])
  return (
    <div>
      <HeaderPart />
      <LoadingOverlay active={loading} spinner text="Loading ...">
        <div className="main_cont">
          <LeftpanelMember />
          <div className="right_cont">
            <div className="right_banner_banner_cnt">
              <div className="my_account_inner">
                <Tabs
                  defaultActiveKey="profile"
                  id="fill-tab-example"
                  className="mb-5 profile_tabbber"
                  activeKey={tabkey}
                  fill
                  onSelect={changeTabKey}
                >
                  <Tab eventKey="profile" title="Profile">
                    <h2>My Profile</h2>
                    <div className="my_account_form">
                      <Form onSubmit={updateUserDetails}>
                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Name *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            className="accnt_field"
                            name="name"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            required
                            value={name}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3 accnt_form accnt__bg"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Email *</Form.Label>
                          <div className="accnt_field p-2">{email}</div>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Phone *</Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder="Enter Phone"
                            className="accnt_field"
                            name="phone"
                            required
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Address *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address"
                            className="accnt_field"
                            name="address"
                            required
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>City *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter City"
                            className="accnt_field"
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                            required
                            name="city"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>State *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter State"
                            className="accnt_field"
                            value={state}
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                            required
                            name="state"
                          />
                        </Form.Group>

                        {/* <Form.Group
                    className="mb-3 accnt_form"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>State *</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter State"
                      className="accnt_field"
                    />
                  </Form.Group> */}

                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Zip Code *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Zip Code"
                            className="accnt_field"
                            value={zipCode}
                            onChange={(e) => {
                              setZipCode(e.target.value);
                            }}
                            required
                            name="zipCode"
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3 accnt_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Country *</Form.Label>
                          <Form.Control
                            as="select"
                            className="modal_t_field"
                            required
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                          >
                            {countryConfig.map((value, key) => {
                              return (
                                <option
                                  key={key}
                                  value={value.key}
                                  selected={country === value.key}
                                >
                                  {value.label}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="accnt_submitt"
                        >
                          Update
                        </Button>
                      </Form>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="payment"
                    tabClassName={user.isGlobalAidUser ? "d-none" : ""}
                    title="Payment"
                  >
                    <h2>Transaction History</h2>
                    {allTransaction.length > 0 && (
                      <DataTable
                        columns={columns}
                        data={allTransaction}
                        pagination
                        paginationServer
                        striped
                        responsive
                        customStyles={customStyles}
                        subHeaderAlign="right"
                        subHeaderWrap
                        paginationPerPage={limit}
                        paginationTotalRows={pagination.totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                      />
                    )}

                    {!add_new_card ? (
                      <>
                        <h2>Saved Payment Method</h2>

                        {saveCards.length > 0 ? (
                          <>
                            {saveCards.map((cards, key) => {
                             
                              return (
                                <div key={key} className="save_card active">
                                  <div className="card_exp">
                                    <p className="card_number">
                                      **** **** **** {cards.card.last4}
                                    </p>

                                    <p className="mb-0">
                                      Valid To
                                      <span>
                                        {cards.card.exp_month}/{cards.card.exp_year}{" "}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="card_name d-flex align-items-center">
                                    <div className="mb-0 mr-3">
                                      {cards.card.brand}
                                    </div>

                                    <p className="mb-0 default_card">
                                      <input
                                        type="radio"
                                        id={cards.id}
                                        name="radio-group"
                                        defaultChecked
                                      />
                                      <label htmlFor={cards.id} />
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div>NO CARD FOUND</div>
                        )}

                        <div className="add_new_card text-center mt-5">
                          <Button
                            variant="primary"
                            type="button"
                            className="prf_submi_button"
                            onClick={() => {
                              setNewCard(true);
                            }}
                          >
                            Add New Card
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <h2>Add Payment Method</h2>

                        <div className="pay_with_card">
                          <div className="paywith_card">
                            <h5>Add New Card</h5>
                            <Form onSubmit={addNewCard}>
                              <Form.Group
                                className="prf_inner_pay"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Name*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Name"
                                  className="prf_pay"
                                  required
                                  onChange={(e) => {
                                    setCardHolderName(e.target.value);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group
                                className="prf_inner_pay"
                                controlId="card-number"
                              >
                                <Form.Label>Card Number*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Card Number"
                                  className="prf_pay"
                                  required
                                  name="card_number"
                                  data-rules={JSON.stringify({
                                    required: true,
                                    creditcard: true,
                                  })}
                                  onChange={(e) => {
                                    setCardNumber(e.target.value);
                                    onChangeHandler(e);
                                  }}
                                />

                                {validationReport.card_number &&
                                !validationReport.card_number.isValid ? (
                                  <small className="text-danger">
                                    {validationReport.card_number.errorMessage}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Row>
                                <Col lg="6" md="6">
                                  <Form.Group
                                    className="prf_inner_pay"
                                    controlId="formBasicPassword"
                                  >
                                    <Form.Label>Expiry Date*</Form.Label>
                                    <Row>
                                      <Col lg="4" md="6">
                                        <Form.Control
                                          type="text"
                                          format="MM"
                                          placeholder="MM"
                                          className="prf_pay"
                                          required
                                          minLength={2}
                                          data-rules={JSON.stringify({
                                            required: true,
                                            month: true,
                                          })}
                                          name="exp_month"
                                          onChange={(e) => {
                                            setExpMonth(e.target.value);
                                            onChangeHandler(e);
                                          }}
                                        />
                                        {validationReport.exp_month &&
                                        !validationReport.exp_month.isValid ? (
                                          <small className="text-danger">
                                            {
                                              validationReport.exp_month
                                                .errorMessage
                                            }
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                      <Col lg="8" md="6">
                                        <Form.Control
                                          type="text"
                                          placeholder="YYYY"
                                          format="YYYY"
                                          minLength={4}
                                          maxLength={4}
                                          className="prf_pay"
                                          required
                                          name="exp_year"
                                          data-rules={JSON.stringify({
                                            required: true,
                                            creditcard: true,
                                            // minlength: 4,
                                            // maxlength: 4,
                                            year: true,
                                          })}
                                          onChange={(e) => {
                                            setExpYear(e.target.value);
                                            onChangeHandler(e);
                                          }}
                                        />
                                        {validationReport.exp_year &&
                                        !validationReport.exp_year.isValid ? (
                                          <small className="text-danger">
                                            {
                                              validationReport.exp_year
                                                .errorMessage
                                            }
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </Col>
                                <Col lg="6" md="6">
                                  <Form.Group
                                    className="prf_inner_pay"
                                    controlId="formBasicPassword12"
                                  >
                                    <Form.Label>CVV*</Form.Label>
                                    <Form.Control
                                      type="password"
                                      placeholder="XXX"
                                      className="prf_pay"
                                      required
                                      name="cvv"
                                      data-rules={JSON.stringify({
                                        required: true,
                                        cvv: true,
                                      })}
                                      onChange={(e) => {
                                        setCvv(e.target.value);
                                        onChangeHandler(e);
                                      }}
                                    />
                                    <div className="cvc_number">
                                      <svg
                                        className="p-CardCvcIcons-svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="var(--colorIconCardCvc)"
                                        role="presentation"
                                      >
                                        <path
                                          opacity=".2"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"
                                        ></path>
                                        <path
                                          opacity=".4"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"
                                        ></path>
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z"
                                        ></path>
                                      </svg>
                                    </div>
                                    {validationReport.cvv &&
                                    !validationReport.cvv.isValid ? (
                                      <small className="text-danger">
                                        {validationReport.cvv.errorMessage}{" "}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <div className="d-flex justify-content-between">
                                <Button
                                  variant="danger"
                                  type="button"
                                  className="prf_submi_button"
                                  onClick={() => {
                                    emptyField();
                                    setNewCard(false);
                                  }}
                                >
                                  Cancel
                                </Button>

                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="prf_submi_button"
                                >
                                  Add
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </>
                    )}
                  </Tab>

                  <Tab eventKey="password" title="Password">
                    <h2>Change Password</h2>
                    <div className="change_pass_section">
                      <Form onSubmit={updatePassword}>
                        <Form.Group
                          className="prf_inner_pay"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>New Password*</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            className="prf_pay"
                            name="new_pass"
                            value={new_pass}
                            required
                            onChange={(e) => {
                              setNewPass(e.target.value);
                            }}
                          />
                        </Form.Group>

                        <Form.Group
                          className="prf_inner_pay"
                          controlId="formBasicPassword258"
                        >
                          <Form.Label>Confirm New Password*</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            className="prf_pay"
                            name="con_pass"
                            value={con_pass}
                            required
                            onChange={(e) => {
                              setConPass(e.target.value);
                            }}
                          />
                          <p className="text-danger">{changePassError}</p>
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="prf_submi_button"
                        >
                          Change Password
                        </Button>
                      </Form>
                    </div>
                  </Tab>
                  <Tab eventKey="paypal" title="Commissions">
                    <h2>Commissions</h2>
                    <div className="change_pass_section text-center">
                      <Form onSubmit={updatePaypalId}>
                        <div className="col-md-12">
                          <Form.Group
                            className="prf_inner_pay"
                            controlId="formBasicEmail"
                          >
                            <h5>Please Choose How You Wish to Be Paid</h5>
                            <div className="row ml-2">
                              <div className="col-md-2"></div>
                              <div className="col-md-4">
                                {user.commissionType == "Direct Deposit" ? (
                                  <>
                                    <Form.Check
                                      type="radio"
                                      name="commission_type"
                                      required
                                      value="Direct Deposit"
                                      id="directCommission"
                                      checked={
                                        commissionTypeWiseSection ? false : true
                                      }
                                      onChange={(e) => {
                                        setcommissionType(e.target.value);
                                        setcommissionTypeWiseSection(false);
                                        setpaypalId(paypalId);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Form.Check
                                      type="radio"
                                      name="commission_type"
                                      required
                                      value="Direct Deposit"
                                      id="directCommission"
                                      //checked={commissionTypeWiseSection ? false : true}
                                      onChange={(e) => {
                                        setcommissionType(e.target.value);
                                        setcommissionTypeWiseSection(false);
                                        setpaypalId(paypalId);
                                      }}
                                    />
                                  </>
                                )}
                                <label
                                  for="directCommission"
                                  style={{ marginLeft: "106px" }}
                                >
                                  Direct Deposit
                                </label>
                              </div>
                              <div className="col-md-3">
                                <Form.Check
                                  type="radio"
                                  name="commission_type"
                                  required
                                  value="PayPal"
                                  id="PaypalCommission"
                                  checked={
                                    commissionTypeWiseSection ? true : false
                                  }
                                  onChange={(e) => {
                                    setcommissionType(e.target.value);
                                    setcommissionTypeWiseSection(true);
                                    setpaypalId(paypalId);
                                  }}
                                />{" "}
                                <label
                                  for="PaypalCommission"
                                  style={{ marginLeft: "52px" }}
                                >
                                  PayPal
                                </label>
                              </div>
                              <div className="col-md-2"></div>
                            </div>
                          </Form.Group>
                          <p>
                            Affiliates who make over $600 in one year will be
                            issued a 1099
                          </p>
                        </div>
                        {commissionTypeWiseSection ? (
                          <>
                            <div className="row">
                              <div className="col-md-3"></div>
                              <div className="col-md-6">
                                <Form.Group
                                  className="prf_inner_pay"
                                  controlId="formBasicEmail"
                                >
                                  {/* <Form.Label>Paypal Email Address*</Form.Label> */}
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Paypal Email Address"
                                    className="prf_pay"
                                    name="paypalId"
                                    value={paypalId}
                                    required={commissionTypeWiseSection}
                                    onChange={(e) => {
                                      setpaypalId(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-3"></div>
                            </div>
                            <p>
                              PayPal -{" "}
                              <a
                                href="https://form.jotform.com/230298115423047"
                                target="_blank"
                                rel="noreferrer"
                              >
                                CLICK HERE
                              </a>{" "}
                              to submit a W-9 to TripValet
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="mt-4">
                              Direct Deposit: -{" "}
                              <a
                                href="https://form.jotform.com/231438325940152"
                                target="_blank"
                                rel="noreferrer"
                              >
                                CLICK HERE
                              </a>{" "}
                              to submit a W-9 & Banking
                            </p>
                          </>
                        )}
                        <Button
                          variant="primary"
                          type="submit"
                          className="prf_submi_button"
                        >
                          Submit
                        </Button>
                      </Form>
                      {/* <p className="mt-4">Get Form W-9 <a href={FormFw9} download>Click here to download</a></p> */}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="planSubcription"
                    tabClassName={user.isGlobalAidUser ? "d-none" : ""}
                    title="Plan"
                  >
                    <h2>Current Plan</h2>
                    <div className="change_pass_section text-center">
                      <div className="row">
                        {currentPlan.packageName ? (
                          <>
                            <div className="col-md-12">
                              <h5>{currentPlan.packageName}</h5>
                              <p>
                                <b>${currentPlan.packagePrice}</b>
                              </p>
                              <p>
                                Expire At:- <b> {currentPlan.endDate}</b>
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <h5>Subscription Not Found</h5>
                            </div>
                          </>
                        )}
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              {currentPlan.packageName && (
                                <>
                                  <Button
                                    className="btn btn-warning"
                                    disabled={hitData}
                                    onClick={() => {
                                      cancelCurrentPlan();
                                    }}
                                  >
                                    Cancel Plan
                                  </Button>
                                </>
                              )}
                            </div>
                            <div className="col-md-6">
                              <Button
                                className="btn btn-primary"
                                disabled={hitData}
                                // onClick={() => {
                                //   updatePlanModal();
                                // }}
                                onClick={() => {
                                  updatePlan();
                                }}
                              >
                                Update Plan
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
      <Modal show={modalshow} onHide={handleModalClose}>
        <Modal.Body>
          <div className="modal_close">
            <h3>Are you sure you want to cancel this plan?</h3>
            <Button
              variant="secondary"
              onClick={handleModalClose}
              className="close_buuut"
            ></Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer_but">
          <Button
            variant="secondary"
            onClick={handleCancelPlan}
            className="yesbut"
          >
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={handleModalClose}
            className="nobut"
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalPlan} onHide={handlePlanModalClose} size="xl">
        <Modal.Body>
          <div className="modal_close">
            <h3>Please choose your plan!</h3>
            <Button
              variant="secondary"
              onClick={handlePlanModalClose}
              className="close_buuut"
            ></Button>
          </div>
          <div className="tvi_member_include">
            {currentPlan.packageName ? (
              <UpdateHirePlan
                products={products}
                currentPackage={currentPlan.packagePrice}
                isStarBenifit={user.isStarBenifit}
              />
            ) : (
              <UpdatePlanLoveTravels
                products={products}
                currentPackage={currentPlan.packagePrice}
                isStarBenifit={user.isStarBenifit}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={successModal} onHide={handleModalCloseSuccess}>
        <Modal.Body>
          <div className="modal_close">
            <h2 className="text-center">Congratulations!</h2>
            <h3>You have successfully updated your new membership plan.</h3>
            <Button
              variant="secondary"
              onClick={handleModalCloseSuccess}
              className="close_buuut"
            ></Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalshowUpdate} onHide={handleModalCloseUpdate}>
        <Modal.Body>
          <div className="modal_close">
            <h3>
              You are about to upgrade your TripValet Membership. When you hit
              the confirm button, you will be charged immediately. Are you ready
              to upgrade?
            </h3>
            <Button
              variant="secondary"
              onClick={handleModalCloseUpdate}
              className="close_buuut"
            ></Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer_but">
          <Button
            variant="secondary"
            onClick={() => {
              updatePlan();
            }}
            className="yesbut"
          >
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={handleModalCloseUpdate}
            className="nobut"
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyAccount;
