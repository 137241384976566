import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//import "./styles.css";
import { Row, Col, Form, Tab, Tabs } from "react-bootstrap";
import LeftpanelMember from "../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../components/layout/header/header";
import "./travelcertificates.css";
// import RichTextEditor from "react-rte";
import DataTable from "react-data-table-component";
import { UserIcon, Email, EmailInv } from "../../assets/images";
import Certificates from "./Certificates";
import axios from "../../axios/axios";
import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import { DATE_AND_TIME_FORMAT } from "../../constants/constant";
const Travelcertificates = ({ onChange }) => {
  const [value, setValue] = useState("");
  const [receiver, setReceiver] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // msg: RichTextEditor.createEmptyValue(),
  });
  const [loading, setLoading] = useState(true);
  const [certis, setAllCertis] = useState([]);
  const [userCerts, setUserCerts] = useState([]);
  const [tableData, setTabledata] = useState([]);
  const [certiSelected, setCerti] = useState("");
  const columns = [
    {
      name: "Sent To Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Sent To Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Send at",
      selector: (row) => moment(row.createdAt).format(DATE_AND_TIME_FORMAT),
      sortable: true,
    },
    {
      name: "Certificate ID",
      selector: (row) => row.certificate.certificateId,
    },
    {
      name: "Certificate Title",
      selector: (row) => row.certificate.certificateTitle,
    },
    {
      name: "Certificate Code",
      selector: (row) => row.certificate.certificateEmbeddedCode,
    },
  ];

  const [tabkey, setTabKey] = useState("single");

  const setMsg = (value) => {
    setValue(value);
    if (onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      onChange(value.toString("html"));
    }
  };

  const fetchCertis = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    // console.log("page row chng", pageNew, pagination.limit);
    await axios
      .get(
        `/all-certificates`,
        config

        // {
        //   headers: {
        //     Authorization: token,
        //   },
        // }
      )
      .then((res) => {
        console.log("get-certis res...", res.data);
        if (res.data.status) {
          setAllCertis(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchUserCertis = async () => {
    // console.log("page row chng", pageNew, pagination.limit);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    setLoading(true);

    await axios
      .get("/get-redeem-certificates", config)
      .then((res) => {
        // console.log("get-crtis res...", res.data);
        setLoading(false);

        if (
          res.data.status &&
          res.data.data !== "No Certificates redeemed by user"
        ) {
          setUserCerts(res.data.data);
        } else {
          setUserCerts([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const fetchSentCertis = async () => {
    // console.log("page row chng", pageNew, pagination.limit);

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    setLoading(true);
    await axios
      .get("/get-sent-certificates", config)
      .then((res) => {
        setLoading(false);
        if (
          res.data.status &&
          res.data.data !== "No Certificates sent by user"
        ) {
          setTabledata(res.data.data);
        } else {
          setTabledata([]);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchCertis();
  }, []);

  const sendMail = async (e) => {
    e.preventDefault();

    if (!certiSelected) {
      message.error("Please select certificate");
      return;
    }

    let data = {
      first_name: receiver.first_name,
      last_name: receiver.last_name,
      email: receiver.email,
      defaultMsg: value,
      certId: certiSelected,
    };

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    setLoading(true);
    await axios
      .post(`/send-mail`, data, config)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          message.success("mail sent");
          setValue("");
          setReceiver({
            first_name: "",
            last_name: "",
            email: "",
          });
          setCerti("");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const tabChange = (key) => {
    setTabKey(key);

    if (key === "view") {
      fetchSentCertis();
    }

    if (key === "marketing") {
      fetchUserCertis();
    }
  };

  return (
    <LoadingOverlay active={loading} spinner text="loading...">
      <HeaderPart />
      <div className="main_cont">
        <LeftpanelMember />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <Tabs
              id="controlled-tab-example"
              className="mb-3"
              defaultActiveKey="single"
              activeKey={tabkey}
              onSelect={(key) => tabChange(key)}
            >
              <Tab eventKey="single" title="Send Single Certificate">
                {tabkey === "single" && (
                  <div className="send_a_certificates">
                    <h2>Send a Certificate</h2>
                    <p>
                      Fill out the form below to email a complimentary vacation
                      certificate of your choice to your prospect
                    </p>
                    <Form onSubmit={sendMail}>
                      <Row>
                        <Col lg="4" md="6" sm="12">
                          <Form.Group
                            className="mb-3 certificate_form"
                            controlId="formBasicText"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={receiver.first_name}
                              placeholder="First Name"
                              className="certificate_field"
                              onChange={(e) =>
                                setReceiver({
                                  ...receiver,
                                  first_name: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="user_iconnn">
                              <img src={UserIcon} alt="UserIcon" />
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                          <Form.Group
                            className="mb-3 certificate_form"
                            controlId="formBasicText"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              className="certificate_field"
                              value={receiver.last_name}
                              onChange={(e) =>
                                setReceiver({
                                  ...receiver,
                                  last_name: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="user_iconnn">
                              <img src={UserIcon} alt="UserIcon" />
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg="4" md="12" sm="12">
                          <Form.Group
                            className="mb-3 certificate_form"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              className="certificate_field"
                              value={receiver.email}
                              onChange={(e) =>
                                setReceiver({
                                  ...receiver,
                                  email: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="user_iconnn">
                              <img src={Email} alt="UserIcon" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="text_editor">
                        {/* <div className="text_editor_heading">
                        <h2>
                          Customize Your Personal Message to your Prospect
                        </h2>
                        {/* <button className="cus_but" disabled type="button">
                          Use selected certificate's default message
                        </button> */}
                        {/* </div>  */}
                        {/* <BodyTextEditor
                        value={value}
                        setValue={(value) => setValue({ value })}
                      /> */}

                        <div className="text_editor">
                          <div className="text_editor_heading">
                            <h2>
                              Customize Your Personal Message to your Prospect
                            </h2>
                            {/* <button className="cus_but" disabled type="button">
                            Use selected certificate's default message
                          </button> */}
                          </div>
                          <Form.Group
                            className="mb-3 certificate_form"
                            controlId="formBasicEmail"
                          >
                            {/* <Form.Label>Email</Form.Label> */}
                            <Form.Control
                              type="textarea"
                              row="15"
                              placeholder="default message"
                              className="certificate_field"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                            />
                          </Form.Group>
                          {/* <CKEditor
                          editor={ClassicEditor}
                          data="<p>Hello from CKEditor 5!</p>"
                          onReady={(editor) => {
                            console.log(
                              "CKEditor5 React Component is ready to use!",
                              editor
                            );
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                          }}
                        /> */}
                        </div>
                        {/* <RichTextEditor value={value} onChange={setMsg} /> */}
                      </div>
                      {certis.length > 0 ? (
                        <Certificates certis={certis} setCerti={setCerti} />
                      ) : (
                        "No Certificates Found"
                      )}
                      <div className="send_invitation_but mb-5">
                        <button className="send_inv">
                          <span>
                            <img src={EmailInv} alt="UserIcon" />
                          </span>
                          Send Certificate
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Tab>

              <Tab eventKey="view" title="View Sent Certificates">
                {tableData && tableData.length > 0 ? (
                  <DataTable
                    title="Sent certificates through email"
                    columns={columns}
                    data={tableData}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={tableData && tableData.length}
                    paginationPerPage={20}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => 20}
                  />
                ) : (
                  "No Sent Certificates"
                )}
              </Tab>

              <Tab eventKey="marketing" title="View Redeemed Certificates">
                {/* <div>
                  {userCerts && userCerts.length > 0 ? (
                    <UserCertificate
                      certis={userCerts}
                      setCerti={setUserCerts}
                    />
                  ) : (
                    "No Certificates Found"
                  )}
                </div> */}
                {userCerts && userCerts.length > 0 ? (
                  <DataTable
                    title="Sent certificates through email"
                    columns={columns}
                    data={userCerts}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={userCerts && userCerts.length}
                    paginationPerPage={20}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => 20}
                  />
                ) : (
                  "No Sent Certificates"
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Travelcertificates;
Travelcertificates.propTypes = {
  onChange: PropTypes.func,
};
