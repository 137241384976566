import React, { useEffect, useRef, useState } from "react";
import { referal_video, video_banner } from "../../assets/video";
import "./referal.css";
import axios from "../../axios/axios";
import { Buffer } from "buffer";
import { Col, Container, Row } from "react-bootstrap";
import {
  gallery_five,
  gallery_four,
  gallery_one,
  gallery_six,
  gallery_three,
  gallery_two,
  gi_01,
  gi_04,
  gi_05,
  imagine,
  incentive_ebook,
  ittyb_bg,
  more_leads,
  more_referarals,
  more_sales,
  pdf,
  repeat_business,
  sales_bg,
  share,
  star,
  Test8,
  Test10,
  Test11,
  Test12,
  Test13,
  Test14,
  Test16,
  Test17,
  Test20,
  Test21,
  Test22,
  Test23,
  Test24,
  Test25,
  Test26,
  Test27,
  Test28,
  Test30,
  Test31,
  sales_professionals,
  digital_marketing,
  small_business,
  insurance,
  reale_state,
  inclusive_total_image,
  cruise_total_image,
} from "../../assets/images";
import ReferalHeader from "./ReferalHeader";
import ReferalFooter from "./ReferalFooter";
import Fancybox from "./Fancybox";
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import { useDispatch } from "react-redux";
import {
  setCode,
  setAffiliateUserIdForLeadGenerate,
} from "../../actions/signUp.actions";
import { increaseHits } from "../../axios/signUp.axios";
import { useHistory, useParams, useLocation } from "react-router-dom";

export default function RefferalPage() {
  const { origin } = window.location;
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [affCode, SetaffCode] = useState("");
  const [affUserId, SetaffUserId] = useState("");
  let timer;
  const location = useLocation();
  const planRef = useRef(null);

  const GALLERY_IMAGE = [
    gallery_five,
    gallery_four,
    gallery_one,
    gallery_six,
    gallery_three,
    gallery_two,
  ];
  const CERTIFICATE_GALLERY = [
    gi_01,
    inclusive_total_image,
    cruise_total_image,
    gi_04,
    gi_05,
  ];
  const TESTIMONIALS = [
    Test8,
    Test10,
    Test11,
    Test12,
    Test13,
    Test14,
    Test16,
    Test17,
    Test20,
    Test21,
    Test22,
    Test23,
    Test24,
    Test25,
    Test26,
    Test27,
    Test28,
    // Test29,
    Test30,
    Test31,
  ];

  const callIncreaseHits = () => {
    if (timer) clearTimeout(timer);
    timer = () =>
      setTimeout(() => {
        increaseHits(params.code)
          .then((res) => {
            console.log(res.data.code_by);
            SetaffCode(res.data.code_by);
          })
          .catch((err) => {});
      }, 500);
    timer();
  };

  useEffect(() => {
    const swiperVo = new Swiper(".swiperVo", {
      loop: true,
      speed: 800,
      slidesPerView: 3.3,
      spaceBetween: 10,
      centeredSlides: true,
      modules: [Navigation, Autoplay],
      navigation: {
        nextEl: ".swiperVo-next",
        prevEl: ".swiperVo-prev",
      },
      autoplay: {
        delay: 60000,
        disableOnInteraction: false,
      },
    });

    const swiperTestimonials = new Swiper(".swiperTestimonials", {
      loop: true,
      speed: 800,
      slidesPerView: 2.05,
      spaceBetween: 10,
      centeredSlides: true,
      modules: [Navigation, Autoplay],
      navigation: {
        nextEl: ".swiperTestimonials-next",
        prevEl: ".swiperTestimonials-prev",
      },
      autoplay: {
        delay: 60000,
        disableOnInteraction: false,
      },
    });

    // const getCookie = (name) => {
    //   const nameEQ = name + "=";
    //   const ca = document.cookie.split(';');
    //   for (let i = 0; i < ca.length; i++) {
    //     let c = ca[i];
    //     while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    //     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    //   }
    //   return;
    // }

    // const myCookieValue = getCookie('myCookie');
    // if (!myCookieValue) {
    //   const setCookie = (name, value, minutes) => {
    //     let d = new Date();
    //     d.setTime(d.getTime() + (minutes * 60 * 1000));
    //     let expires = "expires=" + d.toUTCString();
    //     document.cookie = name + "=" + value + ";" + expires + ";path=/";
    //   }

    //   setCookie('myCookie', 'myValue', 2);
    // }

    const sendMessage = () => {
      const iframe = document.querySelector("#tvi_home");
      console.log(iframe);
      // iframe.contentWindow.postMessage('content send from refer', "*");
      // window.parent.postMessage('CallFunctionA', 'http://localhost:8081');
      // window.parent.postMessage({
      //   'msg': 'works!'
      // }, "*");
      // window.parent.postMessage('msg', '*');
    };

    setTimeout(() => {
      sendMessage();
    }, 1000);

    localStorage.setItem("paramCode", params.code);

    document.title = "TripValet Incentives";
    if (!timer) {
      dispatch(setCode(affCode));
      console.log("calling");

      callIncreaseHits();
    }

    return () => {
      swiperVo.destroy();
      swiperTestimonials.destroy();
    };
  }, []);

  useEffect(() => {
    if (location.hash === "#choosePlan") {
      planRef.current.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (affCode !== "") {
      document.title = "TripValet Incentives";
      dispatch(setCode(affCode));
      getCodeByAffiliateUser(affCode);
      localStorage.setItem("referCodeStore", affCode);
    }
  }, [affCode]);

  const getCodeByAffiliateUser = (code) => {
    axios
      .get(`/affiliate/get-by-code/${code}`)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status) {
          SetaffUserId(res.data.data[0].belongsToUser._id);
          dispatch(
            setAffiliateUserIdForLeadGenerate(
              res.data.data[0].belongsToUser._id
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const gotoOrderCheckoutForm = () => {
    history.push(
      "/order-check-out?encode=" +
        Buffer.from(affCode, "utf-8").toString("base64")
    );
  };

  const gotoOtherPageLink = (link) => {
    history.push(link);
  };

  return (
    <>
      <div className="landing">
        <ReferalHeader gotoOrderCheckoutForm={gotoOrderCheckoutForm} />
        <div className="fixed_bg">
          <video
            crossOrigin="anonymous"
            playsInline=""
            preload="auto"
            muted={true}
            loop={true}
            tabIndex="-1"
            autoPlay={true}
            src={referal_video}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>

        <section
          className="sales"
          style={{
            background: `url(${sales_bg}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <Container>
            <div className="sales_title text-center mb-3">
              <h1 className="section_sales_heading">
                <b>What Is TripValet Incentives?</b>
              </h1>
              <h2>
                2X-5X Sales and Leads
                <br />
                For LESS Than Your Daily Starbucks Order
              </h2>
            </div>

            <Row className="justify-content-center">
              <Col md={10}>
                <Row className="align-items-center">
                  <Col className="sales_text">
                    <h2>How to Instantly Boost Sales by 60% or More...</h2>
                    <p>
                      AND TURN BUYERS INTO LOYAL FANS WITH INCENTIVE MARKETING
                      AND VACATION GIVEAWAYS
                    </p>
                  </Col>
                  <Col className="sales_video">
                    <iframe
                      frameBorder="0"
                      allowFullScreen=""
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      title="TripValet Incentives"
                      width="100%"
                      height="280px"
                      src="https://www.youtube.com/embed/YYnFbvIILko?autoplay=0&amp;mute=0&amp;controls=1&amp;loop=0&amp;origin=https%3A%2F%2Fwww.tripvaletincentives.com&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"
                      id="widget2"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="btn_section text-center mt-5">
              <button
                onClick={gotoOrderCheckoutForm}
                className="btn_style_one rounded-pill btn"
              >
                <span>START A FREE 7 DAY TRIAL </span>
              </button>
            </div>
          </Container>
        </section>

        <section className="video_banner">
          <video
            crossOrigin="anonymous"
            playsInline=""
            preload="auto"
            muted={true}
            loop={true}
            tabIndex="-1"
            autoPlay={true}
            src={video_banner}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </section>

        <section
          className="ittyb text-center text-white"
          style={{
            background: `url(${ittyb_bg}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="ittyb_title text-center">
                  <h2>THE FOUR MOST IMPORTANT THINGS TO YOUR BUSINESS</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} className="ittyb_item">
                <img
                  alt="MORE LEADS"
                  src={more_leads}
                  width={120}
                  height={120}
                />
                <h2>MORE LEADS</h2>
                <p>
                  <i>
                    Ready to generate more leads with your current marketing
                    efforts?
                  </i>
                </p>
              </Col>
              <Col md={3} className="ittyb_item">
                <img
                  alt="MORE SALES"
                  src={more_sales}
                  width={120}
                  height={120}
                />
                <h2>MORE SALES</h2>
                <p>
                  <i>
                    Ready to see higher conversions and reduce new client
                    acquisition cost?
                  </i>
                </p>
              </Col>
              <Col md={3} className="ittyb_item">
                <img
                  alt="REPEAT BUSINESS"
                  src={repeat_business}
                  width={120}
                  height={120}
                />
                <h2>REPEAT BUSINESS</h2>
                <p>
                  <i>
                    Repeat customers can contribute up to 300% of your bottom
                    line-over new clients. Which is worth more to your business?
                  </i>
                </p>
              </Col>
              <Col md={3} className="ittyb_item">
                <img
                  alt="MORE REFERRALS"
                  src={more_referarals}
                  width={120}
                  height={120}
                />
                <h2>MORE REFERRALS</h2>
                <p>
                  <i>
                    The most important business growth strategy? Word-Of-Mouth
                  </i>
                </p>
              </Col>

              <Col md={3} className="ittyb_bt">
                <h3>USE CASE:</h3>
                <p>
                  Generate leads for any business by offering vacation
                  certificates just for finding out more about your company.
                </p>
              </Col>
              <Col md={3} className="ittyb_bt">
                <h3>USE CASE:</h3>
                <p>
                  Stand out from your competition by adding a high valued
                  incentive to your product or service. Give them an extra nudge
                  to buy now instead of in the future.
                </p>
              </Col>
              <Col md={3} className="ittyb_bt">
                <h3>USE CASE:</h3>
                <p>
                  With your TVI Membership you can offer loyal customers
                  vacation offers on the anniversary of their purchase, to
                  encourage buying new products/services, or extending
                  contracts.
                </p>
              </Col>
              <Col md={3} className="ittyb_bt">
                <h3>USE CASE:</h3>
                <p>
                  Always be top of mind with your clients and referral partners
                  when you thank them for their referral with a high value
                  vacation incentive like 6 nights in Mexico or Dream Vacation
                  Weeks.
                </p>
              </Col>
            </Row>
            <div className="btn_section text-center mt-4">
              <button
                onClick={gotoOrderCheckoutForm}
                className="btn_style_one rounded-pill btn"
              >
                <span>START A FREE 7 DAY TRIAL</span>
              </button>
            </div>
          </Container>
        </section>

        <section className="features">
          <Container fluid>
            <Row>
              <Col
                sm={4}
                className="features_item"
                style={{ backgroundColor: "#000" }}
              >
                <div className="fi_card">
                  <img
                    alt="Real estate"
                    src={reale_state}
                    width={120}
                    height={120}
                  />
                  <h2>Real estate</h2>
                  <ul>
                    <li>Home Valuations</li>
                    <li>Opening Houses</li>
                    <li>Closing Gifts</li>
                  </ul>
                  <div className="fi_cta">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        gotoOtherPageLink("/real-estate/" + params.code);
                      }}
                    >
                      Read More{" >"}
                    </a>
                  </div>
                </div>
              </Col>
              <Col
                sm={4}
                className="features_item"
                style={{ backgroundColor: "#6e6968" }}
              >
                <div className="fi_card">
                  <img
                    alt="Insurance"
                    src={insurance}
                    width={120}
                    height={120}
                  />
                  <h2 style={{ color: "#4BD1A0" }}>Insurance</h2>
                  <ul>
                    <li>Free Quotes</li>
                    <li>Policy Anniversary</li>
                    <li>Referrals</li>
                  </ul>
                  <div className="fi_cta">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        gotoOtherPageLink("/insurance/" + params.code);
                      }}
                    >
                      Read More{" >"}
                    </a>
                  </div>
                </div>
              </Col>
              <Col
                sm={4}
                className="features_item"
                style={{ backgroundColor: "#325466" }}
              >
                <div className="fi_card">
                  <img
                    alt="small business"
                    src={small_business}
                    width={120}
                    height={120}
                  />
                  <h2>small business</h2>
                  <ul>
                    <li>Free Quotes</li>
                    <li>Service Extension</li>
                    <li>Additional Services</li>
                  </ul>
                  <div className="fi_cta">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        gotoOtherPageLink("/small-business/" + params.code);
                      }}
                    >
                      Read More{" >"}
                    </a>
                  </div>
                </div>
              </Col>
              <Col
                sm={4}
                className="features_item"
                style={{ backgroundColor: "#ededed" }}
              >
                <div className="fi_card">
                  <img
                    alt="Digital Marketing"
                    src={digital_marketing}
                    width={120}
                    height={120}
                  />
                  <h2 style={{ color: "#4BD1A0" }}>Digital Marketing</h2>
                  <ul style={{ color: "#2f2e2e" }}>
                    <li>Event Bonus</li>
                    <li>Upgrade Packages</li>
                    <li>Fill Events</li>
                  </ul>
                  <div className="fi_cta">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        gotoOtherPageLink("/digital-marketing/" + params.code);
                      }}
                      style={{ color: "#2f2e2e" }}
                    >
                      Read More{" >"}
                    </a>
                  </div>
                </div>
              </Col>
              <Col
                sm={4}
                className="features_item"
                style={{ backgroundColor: "#4bd1a0" }}
              >
                <div className="fi_card">
                  <img
                    alt="ales professionals"
                    src={sales_professionals}
                    width={120}
                    height={120}
                  />
                  <h2>Sales professionals</h2>
                  <ul style={{ color: "#2f2e2e" }}>
                    <li>Free Quotes</li>
                    <li>Upgrade Packages</li>
                    <li>Buy Now Bonus</li>
                  </ul>
                  <div className="fi_cta">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        gotoOtherPageLink(
                          "/sales-professionals/" + params.code
                        );
                      }}
                      style={{ color: "#2f2e2e" }}
                    >
                      Read More{" >"}
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="gift_of_travel_new">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="gt_title text-center">
                  <h2>GIVE THE GIFT OF TRAVEL.</h2>
                  <p>TO THE OVER 6000 AMAZING LOCATIONS WORLDWIDE LIKE</p>
                  <p>
                    PARIS * BALI * TOKOYO * ORLANDO * DUBAI * LONDON * LAS VEGAS
                    * CANCUN * JAMAICA * NEW YORK * ROME * BORA BORA
                  </p>
                </div>
              </Col>
            </Row>

            <Fancybox className="row g-0">
              {GALLERY_IMAGE.map((thumbnail, key) => {
                return (
                  <Col
                    md={4}
                    sm={6}
                    className="gallery_item position-relative"
                    key={key}
                  >
                    <a data-fancybox="gallery" href={thumbnail}>
                      <img
                        alt="gallery"
                        src={thumbnail}
                        className="img-fluid"
                      />
                    </a>
                    <div className="gellery_ovarley" />
                  </Col>
                );
              })}
            </Fancybox>
          </Container>
        </section>

        <section className="banner">
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <Row className="align-items-center">
                  <Col md={3} className="banner_img">
                    <img
                      alt="ebook"
                      src={incentive_ebook}
                      className="img-fluid"
                    />
                  </Col>

                  <Col md={9} className="banner_text text-center">
                    <h2>Incentive Marketing 101</h2>
                    <p>
                      Fortune 500 Companies use incentive based marketing to
                      grow their business...
                      <br />
                      it's time for you to get their results!!
                    </p>
                    <a
                      href={`/lead-generate/${Buffer.from(
                        affUserId,
                        "utf-8"
                      ).toString("base64")}`}
                      target="_blank"
                      className="btn rounded-0"
                    >
                      Learn How!
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="vacation_offers text-white">
          <Container>
            <div className="vo_title text-center">
              <h2>Five Vacation Offers.</h2>
              <h2>Share An Unlimited Number Of Times.</h2>
            </div>

            <div className="swiperVo swiper">
              <Fancybox className="swiper-wrapper">
                {CERTIFICATE_GALLERY.map((thumbnail, key) => {
                  return (
                    <div className="swiper-slide" key={key}>
                      <a data-fancybox="gallery" href={thumbnail}>
                        <img
                          alt="gallery"
                          src={thumbnail}
                          className="img-fluid"
                        />
                        <div className="gi_react" />
                      </a>
                    </div>
                  );
                })}
              </Fancybox>

              <div className="swiperVo-nav">
                <div className="swiper-button-prev swiperVo-prev d-flex align-items-center justify-content-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      viewBox="0 0 17 17"
                      fill="currentcolor"
                    >
                      <path d="m5.207 8.471 7.146 7.147-.707.707-7.853-7.854L11.647.618l.707.707-7.147 7.146z" />
                    </svg>
                  </span>
                </div>
                <div className="swiper-button-next swiperVo-next d-flex align-items-center justify-content-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      viewBox="0 0 17 17"
                      fill="currentcolor"
                    >
                      <path d="m13.207 8.472-7.854 7.854-.707-.707 7.146-7.146-7.146-7.148.707-.707 7.854 7.854z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section className="wwo text-white text-center">
          <Container>
            <div className="wwo_wrapper">
              <Row>
                <Col md={4} className="wwo_item">
                  <img alt="TVI" src={pdf} width="100px" height="100px" />
                  <p>
                    Offer your clients eye catching PDF versions of each
                    vacation incentive or email the vacation offer directly to
                    their inbox
                  </p>
                </Col>
                <Col md={4} className="wwo_item">
                  <img alt="TVI" src={star} width="100px" height="100px" />
                  <p>
                    Personalize your vacation incentives with custom messages
                    and add your logo
                  </p>
                </Col>
                <Col md={4} className="wwo_item">
                  <img alt="TVI" src={share} width="100px" height="100px" />
                  <p>
                    Add vacation incentives to your website and landing pages
                    easy with simple to install embed codes for immediate
                    delivery
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="imagine text-white text-center">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="imagine_title">
                  <h2>Sound Too Good To Be True?</h2>
                  <p>If we had a dollar every time we heard that!!</p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6} className="imagine_text">
                <p>
                  We know how important it is for you to understand how we are
                  able to offer these vacation discounts.
                </p>
                <p>
                  First off, hotels and resorts are rarely full but are always
                  open and still have their fixed costs-whether rooms are used
                  or not. So they partner up with marketing companies like us to
                  help them fill their otherwise empty rooms. They understand
                  that if someone is staying at their property, they can create
                  additional revenue from other parts of their property like
                  in-house restaurants, the bar, the casino, room service, the
                  spa or even an extra day stay.
                </p>
                <p>
                  You're using vacation certificates to increase revenue, repeat
                  and referral business. Hotels and resorts are using vacation
                  certificates to increase revenue, repeat and referral
                  business. Hotels and resorts know that if they show your
                  customers a good time while they’re there, they’ll be more
                  likely to tell their friends and family about the great time
                  they had and will be more likely to book with the property in
                  the future as a regular paying customer.
                </p>
                <p>That's how vacation incentives are a win-win-win. </p>
              </Col>
              <Col md={6} className="imagine_img">
                <img alt="TVI" src={imagine} className="img-fluid" />
              </Col>
            </Row>
            <div className="btn_section text-center mt-3">
              <button
                onClick={gotoOrderCheckoutForm}
                className="btn_style_one btn rounded-pill"
              >
                <span>START A FREE 7 DAY TRIAL</span>
              </button>
            </div>
          </Container>
        </section>

        <section className="trial text-white text-center">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="trial_title">
                  <h2>Risk Free 7 Day Trial</h2>
                  <p>
                    With access to 5 different incentives (3 new ones coming
                    soon) with over 6000 destinations it is impossible to
                    effectively test the TVI platform, testing your offers, in
                    under 7 days. But we are willing to put our money where our
                    mouth is.
                  </p>
                  <p>1. Start your 7 day Free trial</p>
                  <p>2. Send yourself a certificate to see how easy it is</p>
                  <p>3. Watch the onboarding training</p>
                  <p>4. Implement one of our quick win strategies</p>
                  <p>
                    5. If you don't see the value, cancel anytime before your 7
                    days expire!
                  </p>
                  <div className="btn_section text-center ms-3">
                    <button
                      onClick={gotoOrderCheckoutForm}
                      className="btn_style_one btn rounded-pill"
                    >
                      <span ref={planRef}>START A FREE 7 DAY TRIAL</span>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="plan">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="plan_title text-center">
                  <h2>Choose Your Plan</h2>
                  <h5>
                    This is an online subscription-based incentive marketing
                    system with a 7-Day Free Trial. Absolutely NO Contracts or
                    Setup Fees.Cancel Anytime.
                  </h5>
                </div>
              </Col>
            </Row>
            <div className="plan_text_list">
              <Row>
                <Col md={6}>
                  <ul>
                    <li>6500+ Worldwide Destinations</li>
                    <li>
                      5 Unique Travel e-certificates
                      <ul>
                        <li>3 New Incentives Coming SOON!​</li>
                      </ul>
                    </li>
                    <li>
                      Next Level TripValet Incentives
                      <ul>
                        <li>Graphic Templates​</li>
                        <li>Fill In The Blank Social Media Prompts​</li>
                        <li>AI Prompts​</li>
                        <li>Copy and Past Social Media Captions​</li>
                        <li>Image Library​</li>
                      </ul>
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <ul>
                    <li>
                      TVI Academy
                      <ul>
                        <li>
                          Essentials Training for Facebook, Instagram, and
                          LinkedIn
                        </li>
                        <li>​Marketing Training</li>
                        <li>Sales Training​</li>
                        <li>
                          Over 70 videos included and more added regularly!
                        </li>
                      </ul>
                    </li>
                    <li>VIP Travel Membership with Discounts Up to 70%</li>
                    <li>
                      Add Up To 9 Friends and Family As Travel Portal Users
                    </li>
                    <li>
                      Benefit Hub Gives Access To Unique Shopping And
                      Entertainment Offers
                    </li>
                    <li>Private FB Group For Collaboration Marketing Ideas</li>
                    <li>Unlimited Printable Certificates</li>
                    <li>Affiliate Links with Commission Tracking</li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="plan_tires">
              <Row>
                <Col md={3}>
                  <div className="pt_item text-center">
                    <h2>Monthly</h2>
                    <div className="plan_price">$97 every month</div>
                    <br />
                    <p></p>
                    {/* <div className="btn_section">
                    <a href="#" className="btn_style_two btn rounded-pill">
                      <span>GET STARTED</span>
                    </a>
                  </div> */}
                  </div>
                </Col>
                <Col md={3}>
                  <div className="pt_item text-center">
                    <h2>3 Months</h2>
                    <div className="plan_price">$257 every 3 months</div>
                    <p>Save 12%</p>
                    {/* <div className="btn_section">
                    <a href="#" className="btn_style_two btn rounded-pill">
                      <span>GET STARTED</span>
                    </a>
                  </div> */}
                  </div>
                </Col>
                <Col md={3}>
                  <div className="pt_item  text-center">
                    <h2>6 Months</h2>
                    <div className="plan_price">$497 every 6 months</div>
                    <p>Save 15%</p>
                    {/* <div className="btn_section">
                    <a href="#" className="btn_style_two btn rounded-pill">
                      <span>GET STARTED</span>
                    </a>
                  </div> */}
                  </div>
                </Col>
                <Col md={3} className="mt-3">
                  <div className="pt_item border-right-0 text-center">
                    <h2>Annual</h2>
                    <div className="plan_price">$947 every year</div>
                    <p>Save 19%</p>
                    {/* <div className="btn_section">
                    <a href="#" className="btn_style_two btn rounded-pill">
                      <span>GET STARTED</span>
                    </a>
                  </div> */}
                  </div>
                </Col>
                <Col md={4} className="mt-3 d-none">
                  <div className="pt_item text-center">
                    <h2>TVI Star</h2>
                    <div className="plan_price">$1,997 every year</div>
                    {/* <p>Save 19%</p> */}
                    {/* <div className="btn_section">
                    <a href="#" className="btn_style_two btn rounded-pill">
                      <span>GET STARTED</span>
                    </a>
                  </div> */}
                  </div>
                </Col>
                <Col md={4} className="mt-3 d-none">
                  <div className="pt_item border-right-0 text-center">
                    <h2>TVI Star Experience</h2>
                    <div className="plan_price">$4,997 every year</div>
                    {/* <p>Save 19%</p> */}
                    {/* <div className="btn_section">
                    <a href="#" className="btn_style_two btn rounded-pill">
                      <span>GET STARTED</span>
                    </a>
                  </div> */}
                  </div>
                </Col>
                <Col md={12} className="text-center mt-3">
                  <button
                    onClick={gotoOrderCheckoutForm}
                    className="btn_style_two btn rounded-pill"
                  >
                    <span>GET STARTED</span>
                  </button>
                </Col>
              </Row>
            </div>

            <Row className="justify-content-center">
              <Col md={6}>
                <div className="plan_title text-center">
                  <h2>TVI Star Packages*</h2>
                  <h5>
                    Includes everything from the TVI Annual membership with
                    these upgrades:
                  </h5>
                </div>
              </Col>
            </Row>
            <div className="plan_text_list">
              <Row>
                <Col md={6}>
                  <h5 className="text-center">TVI Star $1997</h5>
                  <ul>
                    <li>Ultimate Star Media</li>
                    <ul>
                      <li>Recording Interview</li>
                      <li>Creation of a VLOG like show & podcast</li>
                      <li>
                        Distribution to iHeart radio, apple podcast, amazon
                        Casts, Spotify, YouTube.
                      </li>
                      <li>5 ready to go viral reels</li>
                      <li>Drive transfers for all assets</li>
                    </ul>
                    <li>
                      PR Services: Scouting the right digital paper (200+
                      publications) FOX, ABC, CBS, NBC and subsidiaries
                    </li>
                    <li>
                      Annual HighLevel Account
                      <ul>
                        <li>6 Vacation Certificate Landing Page Templates​</li>
                        <li>6 Workflows with email delivery</li>
                        <li>1 TVI affiliate evergreen webinar funnel</li>
                      </ul>
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <h5 className="text-center">TVI Star Experience $4997</h5>
                  <ul>
                    <li>Everything from the TVI Star Package AND</li>
                    <li>3 Night All Inclusive for 2</li>
                    <ul>
                      <li>2 Day Growth Workshop</li>
                      <li>Group Excursion</li>
                      <li>Health is Wealth Workshop</li>
                      <li>Health is Wealth Workbook and Guide</li>
                      <li>So You Think You Can Pitch Workshop</li>
                    </ul>
                  </ul>
                </Col>
                <Col md={12} className="text-center mt-3">
                  <button
                    onClick={gotoOrderCheckoutForm}
                    className="btn_style_two btn rounded-pill"
                  >
                    <span>GET STARTED</span>
                  </button>
                </Col>
                <Col md={12} className="text-center mt-3">
                  <small>
                    *7 Day Trial does NOT apply for the TVI Star or Unlimited
                    Star Experiences
                  </small>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="get_it">
          <Container>
            <div className="getit_title text-center">
              <h2>Send Yourself A Vacation Offer NOW!</h2>
            </div>
            <div className="vf_wrapper">
              <Row className="align-items-center">
                <Col md={5}>
                  <img alt="TVI" src={gi_05} className="img-fluid" />
                </Col>
                <Col md={7}>
                  <div className="getit_form">
                    <iframe
                      src={`${origin}/customer-facing/${affUserId}/TVIDREAMVAC`}
                      title="7 Night Dream Vacation"
                      style={{ border: "0px #ffffff none" }}
                      name="myiFrame"
                      scrolling="no"
                      frameBorder="1"
                      marginHeight="0px"
                      marginWidth="0px"
                      height="350px"
                      width="100%"
                      allowFullScreen
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="testimonials testimonials_section">
          <Container fluid className="px-0">
            <div className="swiperTestimonials swiper">
              <div className="swiper-wrapper">
                {TESTIMONIALS.map((thumbnail, key) => {
                  return (
                    <div className="swiper-slide" key={key}>
                      <div className="you-slide-image">
                        <img alt="TVI" src={thumbnail} className="img-fluid" />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="swiperVo-nav">
                <div className="swiper-button-prev swiperTestimonials-prev d-flex align-items-center justify-content-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      viewBox="0 0 17 17"
                      fill="currentcolor"
                    >
                      <path d="m5.207 8.471 7.146 7.147-.707.707-7.853-7.854L11.647.618l.707.707-7.147 7.146z" />
                    </svg>
                  </span>
                </div>
                <div className="swiper-button-next swiperTestimonials-next d-flex align-items-center justify-content-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      viewBox="0 0 17 17"
                      fill="currentcolor"
                    >
                      <path d="m13.207 8.472-7.854 7.854-.707-.707 7.146-7.146-7.146-7.148.707-.707 7.854 7.854z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <iframe
          title="tvi_home_iframe"
          className="d-none"
          id="tvi_home"
          src={`https://tripvaletincentives.com?affiliateLinkParamDataSave=${window.location.origin}/refer/${params.code}`}
        />
        <ReferalFooter gotoOrderCheckoutForm={gotoOrderCheckoutForm} />
      </div>
    </>
  );
}
