import "./referal_header.css";
import { brand_logo_two } from "../../assets/images";
import { Facebook, Instagram, YouTube } from "../../assets/images/icons";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect } from "react";
import { increaseHits } from "../../axios/signUp.axios";
import { useDispatch } from "react-redux";
import axios from "../../axios/axios";
import {
  setCode,
  setAffiliateUserIdForLeadGenerate,
} from "../../actions/signUp.actions";

export default function ReferalHeader() {
  let timer;
  const params = useParams();
  const history = useHistory();
  const gotoOtherPage = (link) => {
    history.push(link);
  };
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [affCode, SetaffCode] = useState("");
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  useEffect(() => {
    const callIncreaseHits = () => {
      if (timer) clearTimeout(timer);
      timer = () =>
        setTimeout(() => {
          increaseHits(params.code)
            .then((res) => {
              console.log(res.data.code_by);
              SetaffCode(res.data.code_by);
            })
            .catch((err) => {});
        }, 500);
      timer();
    };
    localStorage.setItem("paramCode", params.code);

    if (!timer) {
      dispatch(setCode(affCode));
      console.log("calling");

      callIncreaseHits();
    }
  }, []);
  useEffect(() => {
    if (affCode !== "") {
      document.title = "TripValet Incentives";
      dispatch(setCode(affCode));
      getCodeByAffiliateUser(affCode);
      localStorage.setItem("referCodeStore", affCode);
    }
  }, [affCode]);

  const getCodeByAffiliateUser = (code) => {
    axios
      .get(`/affiliate/get-by-code/${code}`)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status) {
          dispatch(
            setAffiliateUserIdForLeadGenerate(
              res.data.data[0].belongsToUser._id
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div className="heder_cont">
      <Navbar expand="lg" className="header" data-bs-theme="dark">
        <Container>
          <div className="py-0 navbar-brand">
            <img src={brand_logo_two} className="img-fluid" width={120} />
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "200px" }}
              navbarScroll
            >
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/refer/" + localStorage.getItem("paramCode"));
                }}
              >
                HOME
              </Nav.Link>
              <NavDropdown
                title="INDUSTRIES"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/real-estate/" + params.code);
                  }}
                >
                  Real Estate
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/insurance/" + params.code);
                  }}
                >
                  Insurance
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/digital-marketing/" + params.code);
                  }}
                >
                  Digital Marketing
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/small-business/" + params.code);
                  }}
                >
                  Small Business
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/sales-professionals/" + params.code);
                  }}
                >
                  Sales Professionals
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/small-business/" + params.code);
                }}
              >
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link
                // href="#choosePlan"
                // href={`/refer/${localStorage.getItem("paramCode")}#choosePlan`}
                onClick={() => {
                  gotoOtherPage(
                    "/refer/" +
                      localStorage.getItem("paramCode") +
                      "#choosePlan"
                  );
                }}
              >
                MEMBERSHIPS
              </Nav.Link>

              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/affiliate/" + params.code);
                }}
              >
                BECOME AN AFFILIATE
              </Nav.Link>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/faq/" + params.code);
                }}
              >
                FAQ
              </Nav.Link>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/refer/" + localStorage.getItem("paramCode"));
                }}
              >
                CONTACT
              </Nav.Link>
              <Nav.Link href="/login">LOGIN</Nav.Link>
            </Nav>
            <div className="header_sl d-flex">
              <a
                href="https://www.youtube.com/@tripvaletincentives"
                target="_blank"
              >
                <YouTube />
              </a>
              <a
                href="https://www.facebook.com/tripvaletincentives"
                target="_blank"
              >
                <Facebook />
              </a>
              <a href="https://www.instagram.com/tripvalet/" target="_blank">
                <Instagram />
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
