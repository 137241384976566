import axios from "./axios";
export const login = (creds) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`user/login`, creds)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        reject(err.response.data.message);
      });
  });
};
export const checkUser = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/get/` + userId)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.response.data.message);
      });
  });
};
