import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../axios/axios";
import { message } from "antd";
import { DownArrow, Logo } from "../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { getTokenDetails } from "../axios/payment.axios";
import { payments } from "../actions/payment.actions";
import terms from "../assets/terms/terms_condition.pdf";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import useFormValidation from "../hooks/useFormValidator";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { updatePlanPayment } from "../axios/signUp.axios";
import "./update-plan.css";

export default function UpdateSubscription() {
    const product = useSelector((state) => state.product.details);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [checkBox, setCheckbox] = useState(false);
    const [coupon, setCoupon] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [saveCards, setSaveCards] = useState([]);
    const [card_holder_name, setCardHolderName] = useState("");
    const [card_number, setCardNumber] = useState("");
    const [exp_month, setExpMonth] = useState("");
    const [exp_year, setExpYear] = useState("");
    const [cvv, setCvv] = useState("");
    const [add_new_card, setNewCard] = useState(false);
    const [allLastFourDigitCard, setAllLastFourDigitCard] = useState([]);
    const [selectedCard, setSelectedCard] = useState(saveCards.length > 0 ? saveCards[0].id : null);
    const [interval, setIntervals] = useState({
        count: 0,
        type: "month",
    });
    const [validationReport, validate] = useFormValidation();

    // console.log("product", product);
    const getAllCards = (source, stripe_customer_id) => {

        setLoading(true);

        const formData = {
            source,
            stripe_customer_id,
        };

        axios
            .post("/user/get-user-all-cards-new", formData)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    let lastFourDigit = [];
                    if (res.data.cards.length > 0) {
                        for (let i = 0; i < res.data.cards.length; i++) {
                            lastFourDigit.push(res.data.cards[i].card.last4);
                        }
                        setAllLastFourDigitCard(lastFourDigit);
                    }
                    setSaveCards(res.data.cards);
                } else {
                    message.error(res.data.message);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };
    useEffect(() => {
        let data = localStorage.getItem("userDetails");

        if (data) {
            data = JSON.parse(data);
            setUser(data);
            getAllCards("tvi", data.stripe_customer_id);
        }

        if (product.priceObject.productObject.metadata.interval_overrided) {
            setIntervals({
                count: product.priceObject.productObject.metadata.interval_overrided,
                type: product.priceObject.productObject.metadata
                    .interval_type_overrided,
            });
        } else if (
            product.priceObject.recurring &&
            product.priceObject.recurring.interval_count
        ) {
            setIntervals({
                count: product.priceObject.recurring.interval_count,
                type: product.priceObject.recurring.interval,
            });
        } else if (
            !product.priceObject.recurring &&
            product.priceObject.type === "one_time"
        ) {
            setIntervals({
                count: 12,
                type: "month",
            });
        } else {
            setIntervals({
                count: 0,
                type: "month",
            });
        }
    }, [product]);

    const handleCoupon = async (coupon) => {
        try {
            setLoading(true);

            if (!coupon) {
                setLoading(false);
                message.error("No Coupon given");
                return 0;
            }
            let details = await getTokenDetails(coupon, product.price_id).catch(
                (err) => {
                    setLoading(false);
                    message.error(err);
                }
            );
            let newPrice;

            if (details) {
                if (details.amount_off) {
                    newPrice = product.initialPrice - details.amount_off;
                } else
                    newPrice = parseInt(
                        product.initialPrice -
                        (product.initialPrice * details.percent_off) / 100
                    );
                dispatch(payments.setPrice(newPrice));
                setLoading(false);
                message.success("Coupon applied");
                setCoupon(details.id);
                dispatch(payments.setCoupon(details.id));
            } else {
                dispatch(payments.setPrice(product.initialPrice));
                dispatch(payments.setCoupon(undefined));
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            message.error(error);
            dispatch(payments.setCoupon(undefined));
        }
    };

    const updateSubscription = async () => {
        if (checkBox) {
            let temp = {};
            if (add_new_card) {
                if (card_holder_name !== "" && card_number !== "" && exp_month !== "" && exp_year !== "" && cvv !== "") {
                    for (const [, value] of Object.entries(validationReport)) {
                        if (!value.isValid) return;
                    }
                    if (allLastFourDigitCard.includes(card_number.slice(-4))) {
                        message.error("Card already exists");
                        return;
                    }
                     temp = {
                        userDetails: JSON.parse(localStorage.getItem("userDetails")),
                        cardNumber: card_number,
                        cardName: card_holder_name,
                        cardExpMonth: exp_month,
                        cardExpYear: exp_year,
                        cardCVV: cvv,
                        amount: product.price,
                        price: product.price_id,
                        coupon: product.coupon,
                        priceObject: product.priceObject,
                        source: "tvi",
                    };

                    if (product.coupon) temp.coupon = product.coupon;

                    setLoading(true);
                    await updatePlanPayment(temp)
                        .then((res) => {
                            if (res.data.status) {
                                message.success("Payment successfull.");
                                setLoading(false);


                                history.push("/my-account");

                            } else {
                                message.error(
                                    "Payment was not successfull. Error: " + res.data.message
                                );
                                // message.error("Payment was not successfull. Error: "+ res.data.message)
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            message.error("Payment was not successfull. Error: " + err.message);
                            setLoading(false);
                        });



                } else {
                    message.error("Please fill all the fields");
                    return;
                }
            } else {
                if(!selectedCard){
                    message.error("Please choose a card");
                    return;
                }
                 temp = {
                    userDetails: user,
                    price: product.price_id,
                    coupon: product.coupon,
                    priceObject: product.priceObject,
                    source: "tvi",
                    cardId:selectedCard
                };
    
                if (product.coupon) temp.coupon = product.coupon;
                setLoading(true);
                axios
                .post("/user/update-plan-using-saved-card", temp)
                .then((res) => {
                    if (res.data.status) {
                        message.success("Payment successfull.");
                        setLoading(false);

                        history.push("/my-account");
                    } else {
                        message.error(
                            "Payment was not successfull. Error: " + res.data.message
                        );
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    message.error("Payment was not successfull. Error: " + err.message);
                    setLoading(false);
                });

                
            }

        } else {
            message.error("Please check this box if you want to proceed");
        }
    };

    const onChangeHandler = (e) => {
        let { name, value } = e.target;

        let rules = JSON.parse(e.target.dataset.rules);

        validate(value, rules, name);
    };

    const emptyField = () => {
        setCardHolderName("");
        setCardNumber("");
        setExpMonth("");
        setExpYear("");
        setExpYear("");
        setCvv("");
    };
    const addNewCard = (e) => {

    }

    return (
        // <LoadingOverlay active={loading} spinner text="Loading ...">
        //     {product && (
        //         <div className="sign_up_partts_update_plan">
        //             <div className="card_page_logo_image">
        //                 <img src={Logo} alt="logo" className="mb-4 img-fluid" />
        //                 <div className="row">
        //                     <div className="col-md-8">



        //                         {!add_new_card ? (
        //                             <>
        //                                 <div className="p-6">
        //                                     <h2 className="text-xl font-semibold mb-4 text-center">
        //                                         Choose Payment Method
        //                                     </h2>

        //                                     {/* Swiper Slider */}
        //                                     <Swiper
        //                                         modules={[Navigation, Pagination]}
        //                                         spaceBetween={10}
        //                                         slidesPerView={3}
        //                                         pagination={{ clickable: true }}
        //                                         className="saved_card_slider_"
        //                                         breakpoints={{
        //                                             320: {
        //                                               navigation: {
        //                                                 enabled: false,
        //                                               },
        //                                             }
        //                                         }}
        //                                     >
        //                                         {saveCards.map((cards, key) => (
        //                                             <SwiperSlide key={cards.id}>
        //                                                 <label key={key}  className="saved_card">
        //                                                     <div className="saved_card_conteiner"> 
        //                                                         <div className="card_name_">{cards.card.brand}</div>
        //                                                         <div className="card_number_">
        //                                                             **** **** **** {cards.card.last4}
        //                                                         </div>
        //                                                         <div className="valid_till_">
        //                                                             <div className="valid_till_label_">Valid To</div>
        //                                                             <div className="valid_till_value">
        //                                                                 {cards.card.exp_month}/{cards.card.exp_year}{" "}
        //                                                             </div>
        //                                                         </div>
        //                                                         <input
        //                                                             type="radio"
        //                                                             id={cards.id}
        //                                                             name="radio-group"
        //                                                             onClick={() => setSelectedCard(cards.id)}
        //                                                             checked={selectedCard === cards.id}
        //                                                         />
        //                                                         <span className="card_marker"></span>
        //                                                     </div>
        //                                                 </label>
        //                                             </SwiperSlide>
        //                                         ))}

        //                                         {/* Add New Card Option */}
        //                                         <SwiperSlide>
        //                                             <Button
        //                                                 variant="default"
        //                                                 className="add_card_"
        //                                                 onClick={() => {
        //                                                     setNewCard(true);
        //                                                 }}
        //                                             >
        //                                                 + Add New Card
        //                                             </Button>
        //                                         </SwiperSlide>
        //                                     </Swiper>
        //                                 </div>
        //                             </>
        //                         ) : (
        //                             <>
        //                                 <h2>Add Payment Method</h2>

        //                                 <div className="pay_with_card">
        //                                     <div className="paywith_card">
        //                                         <h5>Add New Card</h5>
        //                                         <Form>
        //                                             <Form.Group
        //                                                 className="prf_inner_pay"
        //                                                 controlId="formBasicEmail"
        //                                             >
        //                                                 <Form.Label>Name*</Form.Label>
        //                                                 <Form.Control
        //                                                     type="text"
        //                                                     placeholder="Enter Name"
        //                                                     className="prf_pay"
        //                                                     required
        //                                                     onChange={(e) => {
        //                                                         setCardHolderName(e.target.value);
        //                                                     }}
        //                                                 />
        //                                             </Form.Group>

        //                                             <Form.Group
        //                                                 className="prf_inner_pay"
        //                                                 controlId="card-number"
        //                                             >
        //                                                 <Form.Label>Card Number*</Form.Label>
        //                                                 <Form.Control
        //                                                     type="text"
        //                                                     placeholder="Enter Card Number"
        //                                                     className="prf_pay"
        //                                                     required
        //                                                     name="card_number"
        //                                                     data-rules={JSON.stringify({
        //                                                         required: true,
        //                                                         creditcard: true,
        //                                                     })}
        //                                                     onChange={(e) => {
        //                                                         setCardNumber(e.target.value);
        //                                                         onChangeHandler(e);
        //                                                     }}
        //                                                 />

        //                                                 {validationReport.card_number &&
        //                                                     !validationReport.card_number.isValid ? (
        //                                                     <small className="text-danger">
        //                                                         {validationReport.card_number.errorMessage}
        //                                                     </small>
        //                                                 ) : (
        //                                                     ""
        //                                                 )}
        //                                             </Form.Group>
        //                                             <Row>
        //                                                 <Col lg="6" md="6">
        //                                                     <Form.Group
        //                                                         className="prf_inner_pay"
        //                                                         controlId="formBasicPassword"
        //                                                     >
        //                                                         <Form.Label>Expiry Date*</Form.Label>
        //                                                         <Row>
        //                                                             <Col lg="4" md="6">
        //                                                                 <Form.Control
        //                                                                     type="text"
        //                                                                     format="MM"
        //                                                                     placeholder="MM"
        //                                                                     className="prf_pay"
        //                                                                     required
        //                                                                     minLength={2}
        //                                                                     data-rules={JSON.stringify({
        //                                                                         required: true,
        //                                                                         month: true,
        //                                                                     })}
        //                                                                     name="exp_month"
        //                                                                     onChange={(e) => {
        //                                                                         setExpMonth(e.target.value);
        //                                                                         onChangeHandler(e);
        //                                                                     }}
        //                                                                 />
        //                                                                 {validationReport.exp_month &&
        //                                                                     !validationReport.exp_month.isValid ? (
        //                                                                     <small className="text-danger">
        //                                                                         {
        //                                                                             validationReport.exp_month
        //                                                                                 .errorMessage
        //                                                                         }
        //                                                                     </small>
        //                                                                 ) : (
        //                                                                     ""
        //                                                                 )}
        //                                                             </Col>
        //                                                             <Col lg="8" md="6">
        //                                                                 <Form.Control
        //                                                                     type="text"
        //                                                                     placeholder="YYYY"
        //                                                                     format="YYYY"
        //                                                                     minLength={4}
        //                                                                     maxLength={4}
        //                                                                     className="prf_pay"
        //                                                                     required
        //                                                                     name="exp_year"
        //                                                                     data-rules={JSON.stringify({
        //                                                                         required: true,
        //                                                                         creditcard: true,
        //                                                                         // minlength: 4,
        //                                                                         // maxlength: 4,
        //                                                                         year: true,
        //                                                                     })}
        //                                                                     onChange={(e) => {
        //                                                                         setExpYear(e.target.value);
        //                                                                         onChangeHandler(e);
        //                                                                     }}
        //                                                                 />
        //                                                                 {validationReport.exp_year &&
        //                                                                     !validationReport.exp_year.isValid ? (
        //                                                                     <small className="text-danger">
        //                                                                         {
        //                                                                             validationReport.exp_year
        //                                                                                 .errorMessage
        //                                                                         }
        //                                                                     </small>
        //                                                                 ) : (
        //                                                                     ""
        //                                                                 )}
        //                                                             </Col>
        //                                                         </Row>
        //                                                     </Form.Group>
        //                                                 </Col>
        //                                                 <Col lg="6" md="6">
        //                                                     <Form.Group
        //                                                         className="prf_inner_pay"
        //                                                         controlId="formBasicPassword12"
        //                                                     >
        //                                                         <Form.Label>CVV*</Form.Label>
        //                                                         <Form.Control
        //                                                             type="password"
        //                                                             placeholder="XXX"
        //                                                             className="prf_pay"
        //                                                             required
        //                                                             name="cvv"
        //                                                             data-rules={JSON.stringify({
        //                                                                 required: true,
        //                                                                 cvv: true,
        //                                                             })}
        //                                                             onChange={(e) => {
        //                                                                 setCvv(e.target.value);
        //                                                                 onChangeHandler(e);
        //                                                             }}
        //                                                         />
        //                                                         <div className="cvc_number">
        //                                                             <svg
        //                                                                 className="p-CardCvcIcons-svg"
        //                                                                 width="40"
        //                                                                 height="40"
        //                                                                 viewBox="0 0 24 24"
        //                                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                                 fill="var(--colorIconCardCvc)"
        //                                                                 role="presentation"
        //                                                             >
        //                                                                 <path
        //                                                                     opacity=".2"
        //                                                                     fillRule="evenodd"
        //                                                                     clipRule="evenodd"
        //                                                                     d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"
        //                                                                 ></path>
        //                                                                 <path
        //                                                                     opacity=".4"
        //                                                                     fillRule="evenodd"
        //                                                                     clipRule="evenodd"
        //                                                                     d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"
        //                                                                 ></path>
        //                                                                 <path
        //                                                                     fillRule="evenodd"
        //                                                                     clipRule="evenodd"
        //                                                                     d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z"
        //                                                                 ></path>
        //                                                             </svg>
        //                                                         </div>
        //                                                         {validationReport.cvv &&
        //                                                             !validationReport.cvv.isValid ? (
        //                                                             <small className="text-danger">
        //                                                                 {validationReport.cvv.errorMessage}{" "}
        //                                                             </small>
        //                                                         ) : (
        //                                                             ""
        //                                                         )}
        //                                                     </Form.Group>
        //                                                 </Col>
        //                                             </Row>
        //                                             <div className="d-flex justify-content-between">
        //                                                 <Button
        //                                                     variant="danger"
        //                                                     type="button"
        //                                                     className="prf_submi_button"
        //                                                     onClick={() => {
        //                                                         emptyField();
        //                                                         setNewCard(false);
        //                                                     }}
        //                                                 >
        //                                                     Cancel
        //                                                 </Button>
        //                                             </div>
        //                                         </Form>
        //                                     </div>
        //                                 </div>
        //                             </>
        //                         )}
        //                     </div>
        //                     <div className="col-md-4">
        //                         <div className="d-flex justify-content-center mb-5">
        //                             <div className="order_summery" id="order_summery">
        //                                 <div className="ord_inner">
        //                                     <h3>Order summary</h3>
        //                                     <div className="plan_summ">
        //                                         <div className="plan_left">Plan</div>
        //                                         <div className="plan_right">{product.name}</div>
        //                                     </div>
        //                                     <div className="plan_summ">
        //                                         <div className="plan_left">Duration</div>
        //                                         <div className="plan_right">Until canceled</div>
        //                                     </div>
        //                                     <div className="coupon_code">
        //                                         <button
        //                                             type="button"
        //                                             className="coupon_but"
        //                                             onClick={() =>
        //                                                 visible ? setVisible(false) : setVisible(true)
        //                                             }
        //                                         >
        //                                             Enter a coupon code{" "}
        //                                             <img src={DownArrow} alt="downArrow" />
        //                                         </button>
        //                                         {visible && (
        //                                             <div>
        //                                                 <Form.Group
        //                                                     className={"mb-3 "}
        //                                                     controlId="exampleForm.ControlInput1"
        //                                                 >
        //                                                     <Form.Control
        //                                                         type="text"
        //                                                         placeholder="Enter a coupon code"
        //                                                         onChange={(event) => {
        //                                                             setCoupon(event.target.value);
        //                                                         }}
        //                                                     />
        //                                                 </Form.Group>
        //                                                 <Button
        //                                                     type="button"
        //                                                     className="apply_but"
        //                                                     disabled={!coupon}
        //                                                     onClick={() => {
        //                                                         handleCoupon(coupon);
        //                                                     }}
        //                                                 >
        //                                                     Apply
        //                                                 </Button>
        //                                             </div>
        //                                         )}
        //                                     </div>

        //                                     <div className="total_amnt">
        //                                         <div className="text_l">Total</div>
        //                                         {interval.count === 12 ? (
        //                                             <>
        //                                                 <div className="text_r">
        //                                                     ${product.price}
        //                                                     <span>
        //                                                         {interval.count > 0 &&
        //                                                             "For " +
        //                                                             interval.count +
        //                                                             " " +
        //                                                             interval.type +
        //                                                             "s"}
        //                                                     </span>
        //                                                 </div>
        //                                             </>
        //                                         ) : (
        //                                             <>
        //                                                 <div className="text_r">
        //                                                     ${product.price}
        //                                                     <span>
        //                                                         {interval.count > 0 &&
        //                                                             "Every " + interval.count + " " + interval.type}
        //                                                     </span>
        //                                                 </div>
        //                                             </>
        //                                         )}
        //                                     </div>

        //                                     <Form>
        //                                         <Form.Group
        //                                             className="my-4 ml-3"
        //                                             controlId="formBasicCheckbox"
        //                                         >
        //                                             <Form.Check
        //                                                 type="checkbox"
        //                                                 checked={checkBox}
        //                                                 label={
        //                                                     <>
        //                                                         I have read and accept the{" "}
        //                                                         <a href={terms} target="_blank" rel="noreferrer">
        //                                                             Membership Policy
        //                                                         </a>
        //                                                         .
        //                                                     </>
        //                                                 }
        //                                                 onChange={() => {
        //                                                     setCheckbox(!checkBox);
        //                                                 }}
        //                                                 id="terms"
        //                                             />
        //                                         </Form.Group>

        //                                         <Button
        //                                             className="apply_but mt-2"
        //                                             onClick={updateSubscription}
        //                                         >
        //                                             Buy Now
        //                                         </Button>
        //                                     </Form>
        //                                 </div>
        //                             </div>
        //                         </div>

        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )}
        // </LoadingOverlay>




        <LoadingOverlay active={loading} spinner text="Loading ...">
            {product && (
                <div className="sign_up_partts_update_plan">
                    <div className="card_page_logo_image">
                        <img src={Logo} alt="logo" className="mb-4 img-fluid" />
                        <div className="row">
                            <div className="col-md-8">



                                {!add_new_card ? (
                                    <>
                                        <div className="p-6">
                                            <h2 className="text-xl font-semibold mb-4 text-center">
                                                Choose Payment Method
                                            </h2>

                                            <div className="row">
                                                {saveCards.map((cards, key) => (
                                                    <div className="col-md-4 form-group" key={cards.id}>
                                                        <label key={key}  className="saved_card">
                                                            <div className="saved_card_conteiner"> 
                                                                <div className="card_name_">{cards.card.brand}</div>
                                                                <div className="card_number_">
                                                                    **** **** **** {cards.card.last4}
                                                                </div>
                                                                <div className="valid_till_">
                                                                    <div className="valid_till_label_">Valid To</div>
                                                                    <div className="valid_till_value">
                                                                        {cards.card.exp_month}/{cards.card.exp_year}{" "}
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    type="radio"
                                                                    id={cards.id}
                                                                    name="radio-group"
                                                                    onClick={() => setSelectedCard(cards.id)}
                                                                    checked={selectedCard === cards.id}
                                                                />
                                                                <span className="card_marker"></span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                ))}
                                                <div className="col-md-4 form-group">
                                                <Button
                                                        variant="default"
                                                        className="add_card_"
                                                        onClick={() => {
                                                            setNewCard(true);
                                                        }}
                                                    >
                                                        + Add New Card
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h2>Add Payment Method</h2>

                                        <div className="pay_with_card">
                                            <div className="paywith_card">
                                                <h5>Add New Card</h5>
                                                <Form>
                                                    <Form.Group
                                                        className="prf_inner_pay"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label>Name*</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            className="prf_pay"
                                                            required
                                                            onChange={(e) => {
                                                                setCardHolderName(e.target.value);
                                                            }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group
                                                        className="prf_inner_pay"
                                                        controlId="card-number"
                                                    >
                                                        <Form.Label>Card Number*</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Card Number"
                                                            className="prf_pay"
                                                            required
                                                            name="card_number"
                                                            data-rules={JSON.stringify({
                                                                required: true,
                                                                creditcard: true,
                                                            })}
                                                            onChange={(e) => {
                                                                setCardNumber(e.target.value);
                                                                onChangeHandler(e);
                                                            }}
                                                        />

                                                        {validationReport.card_number &&
                                                            !validationReport.card_number.isValid ? (
                                                            <small className="text-danger">
                                                                {validationReport.card_number.errorMessage}
                                                            </small>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Group>
                                                    <Row>
                                                        <Col lg="6" md="6">
                                                            <Form.Group
                                                                className="prf_inner_pay"
                                                                controlId="formBasicPassword"
                                                            >
                                                                <Form.Label>Expiry Date*</Form.Label>
                                                                <Row>
                                                                    <Col lg="4" md="6">
                                                                        <Form.Control
                                                                            type="text"
                                                                            format="MM"
                                                                            placeholder="MM"
                                                                            className="prf_pay"
                                                                            required
                                                                            minLength={2}
                                                                            data-rules={JSON.stringify({
                                                                                required: true,
                                                                                month: true,
                                                                            })}
                                                                            name="exp_month"
                                                                            onChange={(e) => {
                                                                                setExpMonth(e.target.value);
                                                                                onChangeHandler(e);
                                                                            }}
                                                                        />
                                                                        {validationReport.exp_month &&
                                                                            !validationReport.exp_month.isValid ? (
                                                                            <small className="text-danger">
                                                                                {
                                                                                    validationReport.exp_month
                                                                                        .errorMessage
                                                                                }
                                                                            </small>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Col>
                                                                    <Col lg="8" md="6">
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="YYYY"
                                                                            format="YYYY"
                                                                            minLength={4}
                                                                            maxLength={4}
                                                                            className="prf_pay"
                                                                            required
                                                                            name="exp_year"
                                                                            data-rules={JSON.stringify({
                                                                                required: true,
                                                                                creditcard: true,
                                                                                // minlength: 4,
                                                                                // maxlength: 4,
                                                                                year: true,
                                                                            })}
                                                                            onChange={(e) => {
                                                                                setExpYear(e.target.value);
                                                                                onChangeHandler(e);
                                                                            }}
                                                                        />
                                                                        {validationReport.exp_year &&
                                                                            !validationReport.exp_year.isValid ? (
                                                                            <small className="text-danger">
                                                                                {
                                                                                    validationReport.exp_year
                                                                                        .errorMessage
                                                                                }
                                                                            </small>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" md="6">
                                                            <Form.Group
                                                                className="prf_inner_pay"
                                                                controlId="formBasicPassword12"
                                                            >
                                                                <Form.Label>CVV*</Form.Label>
                                                                <Form.Control
                                                                    type="password"
                                                                    placeholder="XXX"
                                                                    className="prf_pay"
                                                                    required
                                                                    name="cvv"
                                                                    data-rules={JSON.stringify({
                                                                        required: true,
                                                                        cvv: true,
                                                                    })}
                                                                    onChange={(e) => {
                                                                        setCvv(e.target.value);
                                                                        onChangeHandler(e);
                                                                    }}
                                                                />
                                                                <div className="cvc_number">
                                                                    <svg
                                                                        className="p-CardCvcIcons-svg"
                                                                        width="40"
                                                                        height="40"
                                                                        viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="var(--colorIconCardCvc)"
                                                                        role="presentation"
                                                                    >
                                                                        <path
                                                                            opacity=".2"
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"
                                                                        ></path>
                                                                        <path
                                                                            opacity=".4"
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"
                                                                        ></path>
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                {validationReport.cvv &&
                                                                    !validationReport.cvv.isValid ? (
                                                                    <small className="text-danger">
                                                                        {validationReport.cvv.errorMessage}{" "}
                                                                    </small>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <div className="d-flex justify-content-between">
                                                        <Button
                                                            variant="danger"
                                                            type="button"
                                                            className="prf_submi_button"
                                                            onClick={() => {
                                                                emptyField();
                                                                setNewCard(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex justify-content-center mb-5">
                                    <div className="order_summery" id="order_summery">
                                        <div className="ord_inner">
                                            <h3>Order summary</h3>
                                            <div className="plan_summ">
                                                <div className="plan_left">Plan</div>
                                                <div className="plan_right">{product.name}</div>
                                            </div>
                                            <div className="plan_summ">
                                                <div className="plan_left">Duration</div>
                                                <div className="plan_right">Until canceled</div>
                                            </div>
                                            <div className="coupon_code">
                                                <button
                                                    type="button"
                                                    className="coupon_but"
                                                    onClick={() =>
                                                        visible ? setVisible(false) : setVisible(true)
                                                    }
                                                >
                                                    Enter a coupon code{" "}
                                                    <img src={DownArrow} alt="downArrow" />
                                                </button>
                                                {visible && (
                                                    <div>
                                                        <Form.Group
                                                            className={"mb-3 "}
                                                            controlId="exampleForm.ControlInput1"
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter a coupon code"
                                                                onChange={(event) => {
                                                                    setCoupon(event.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <Button
                                                            type="button"
                                                            className="apply_but"
                                                            disabled={!coupon}
                                                            onClick={() => {
                                                                handleCoupon(coupon);
                                                            }}
                                                        >
                                                            Apply
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="total_amnt">
                                                <div className="text_l">Total</div>
                                                {interval.count === 12 ? (
                                                    <>
                                                        <div className="text_r">
                                                            ${product.price}
                                                            <span>
                                                                {interval.count > 0 &&
                                                                    "For " +
                                                                    interval.count +
                                                                    " " +
                                                                    interval.type +
                                                                    "s"}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="text_r">
                                                            ${product.price}
                                                            <span>
                                                                {interval.count > 0 &&
                                                                    "Every " + interval.count + " " + interval.type}
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <Form>
                                                <Form.Group
                                                    className="my-4 ml-3"
                                                    controlId="formBasicCheckbox"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={checkBox}
                                                        label={
                                                            <>
                                                                I have read and accept the{" "}
                                                                <a href={terms} target="_blank" rel="noreferrer">
                                                                    Membership Policy
                                                                </a>
                                                                .
                                                            </>
                                                        }
                                                        onChange={() => {
                                                            setCheckbox(!checkBox);
                                                        }}
                                                        id="terms"
                                                    />
                                                </Form.Group>

                                                <Button
                                                    className="apply_but mt-2"
                                                    onClick={updateSubscription}
                                                >
                                                    Buy Now
                                                </Button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LoadingOverlay>
    );
}
