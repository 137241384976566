import React, { useState, useEffect } from "react";
//import "./styles.css";
// import {  Row, Col } from 'react-bootstrap';
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import "./events.css";
import Moment from "react-moment";
import axios from "../../../axios/axios";
// import BodyTextEditor from "./BodyTextEditor";
import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";

const DashboardPart = () => {
  const [loading, setLoading] = useState(true);
  const [allCals, setAllCals] = useState([]);

  const fetchCals = async () => {
    let data = { portal: "TripValetIncentive" };
    await axios
      .post(`/selected-calendar`, data)
      .then((res) => {
        if (res.data.status) {
          setAllCals(res.data.data);
          setLoading(false);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchCals();
  }, []);

  return (
    <LoadingOverlay active={loading} spinner text="Loading ...">
      <HeaderPart />
      <div className="main_cont">
        <LeftpanelMember />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <div className="event_heading">
              <h2>Event Gallery</h2>
            </div>
            <div className="event_image_group">
              {allCals.length > 0
                ? allCals.map((cal) => {
                    return (
                      <div className="event_image_inner_group">
                        <div className="event_image_inner_box">
                          <a
                            href={cal.calendarImg}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={cal.calendarImg} alt="icon" />
                            <p>
                              <Moment format="DD-MMM-YYYY">{cal.date}</Moment>
                            </p>
                            <p>{cal.calendarName}</p>
                          </a>
                        </div>
                      </div>
                    );
                  })
                : "No Calendar uploaded for Current Month"}
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default DashboardPart;
