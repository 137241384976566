import axios from "../axios/axios";

const fetchData = async () => {
    try {
      const response = await axios.get("/stripe-tvi-plan");
      if (response.data.status) {
        return response.data;
      } else {
        console.error("Error: Data status is false");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
  
  const data = await fetchData();
   console.log(data.data); 
  

export const products = data.data;

