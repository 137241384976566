import React from "react";
import { AllInclusive } from "../../assets/images";
import SingleCerti from "./SingleCerti";

const Certificates = ({ certis, setCerti }) => {
  return (
    <div className="complementary_certificate">
      <h2>Select a Complimentary Certificate</h2>
      <div className="complimentary_inner">
        {certis.length > 0
          ? certis.map((certi) => {
              if (
                certi.certificateId != "SFX5NIGHT" &&
                certi.certificateId != "SFX7NIGHT"
              ) {
                return (
                  <SingleCerti
                    certiId={certi._id}
                    certiImg={
                      certi.certificateImg != ""
                        ? certi.certificateImg
                        : AllInclusive
                    }
                    certificateTitle={certi.certificateTitle}
                    setCerti={setCerti}
                    certiIndentity={certi.certificateId}
                  />
                );
              }
            })
          : "No certificate"}

        {/* <label className="radio-img">
          <input type="radio" name="layout" value="S|S|S" />
          <div className="image">
            <img src={NightMexico} />
            <span>5 Night Mexico</span>
          </div>
        </label>

        <label className="radio-img">
          <input type="radio" name="layout" value="M|M" />
          <div className="image">
            <img src={Dream7Night} />
            <span>7 Night Dream Vacation</span>
          </div>
        </label>
        <label className="radio-img">
          <input type="radio" name="layout" value="s|M" />
          <div className="image">
            <img src={Destination3500} />
            <span>7 Night 3500 Destinations</span>
          </div>
        </label>
        <label className="radio-img">
          <input type="radio" name="layout" value="f|M" />
          <div className="image">
            <img src={Night7Mexico} />
            <span>7 Night Mexico</span>
          </div>
        </label> */}
      </div>
    </div>
  );
};

export default Certificates;
