import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "./components/layout/layout";
// import DashboardPage from './pages/dashboard/dashboard';
import DashboardMemberPage from "./pages/dashboard/dashboard-member";
import TravelCertificates from "./pages/travelcertificates/travelcertificates";
// import AddLink from "./pages/admin-dashboard/addlink/addlink";
// import UserDetails from "./pages/admin-dashboard/userdetails/user-details";
// import AddCertificate from "./pages/admin-dashboard/addcertificate/addcertificate";
// import TransactionDetails from "./pages/admin-dashboard/transactiondetails/transactiondetails";
// import BulkUploadCertificates from "./pages/admin-dashboard/addcertificate/bulkuploadcertificate";

// import AddMember from "./pages/admin-dashboard/memberdetails/addmember";
// import BulkUploadMember from "./pages/admin-dashboard/memberdetails/bulkuploadmember";
import LoginPage from "./pages/login/login";
import PaymentPage from "./pages/payment/payment";
import EventPage from "./pages/dashboard/calendar-of-events/events";
import WixTemplate from "./pages/dashboard/resources/wix-template/wix-template";
import ShareableImages from "./pages/dashboard/resources/shareable-images/shareable-images";
import CertificateGuide from "./pages/dashboard/resources/certificate-guide/certificate-guide";
import Faqs from "./pages/dashboard/resources/faqs/faqs";
import SuccessPage from "./pages/payment/success/success";
import FailedPage from "./pages/payment/failed/failed";

import LogoutPage from "./pages/logout/logout";
import ComingSoon from "./pages/coming-soon/coming-soon";
import Print from "./pages/travelcertificates/print/print";
import PlanPart from "./pages/signup/plan";
import CheckoutPart from "./pages/signup/checkout";
import AffiliateDashboard from "./pages/affiliate-dashboard/affiliate-dashboard";
import AffiliatePlanPart from "./pages/signup/affiliate-plan-part";

import MyAccount from "./pages/dashboard/my-account/my-account";
import ForgetPassword from "./pages/login/ForgetPassword";
import TviRedeem from "./pages/tvi-redeem/tvi-redeem";
import ThankYou from "./pages/tvi-redeem/thank-you";
import DownloadTravelCertificate from "./pages/travelcertificates/print/DownloadTravelCertificate";
import TravelCertificateDashboard from "./pages/dashboard/travel-certificate-dashboard/travel-certificate-dashboard";
import EmbedCodePage from "./pages/dashboard/embed-code-page/embed-code-page";
import CustomerFacingPage from "./pages/dashboard/customer-facing/customer-facing";
import TrainingVideo from "./pages/dashboard/training-video-dashboard/training-video-dashboard";
import ResourcesDashbaord from "./pages/dashboard/resources-dashboard/resources-dashboard";
import TviAcademy from "./pages/dashboard/training-video-dashboard/tvi-academy/tvi-academy";
import UpdatePlan from "./pages/signup/update-plan";
import UpdatePlanPayment from "./pages/signup/update-plan-payment";
import EmbeddedAppsTVI from "./pages/signup/embedded-app-tvi";
import PreferredPartnerTab from "./pages/dashboard/partners/partner";
import OrderCheckoutPart from "./pages/signup/order-checkout";
import OrderCheckoutStripeCheckOutForm from "./pages/signup/order-checkout-stripe-checkout-form";
import OrderCheckoutStripeRedirect from "./pages/signup/order-checkout-redirect";
import OrderCheckoutPaymentSuccess from "./pages/signup/order-checkout-payment-success";
import OrderCheckoutPaymentFailed from "./pages/signup/order-checkout-payment-failed";
import RefferalPage from "./pages/reffer/RefferalPage";
import LeadGenerateForm from "./pages/signup/lead-generate-form";
import FAQ from "./pages/faq/FAQ";
import Affiliate from "./pages/affiliate/Affiliate";
import RealEstate from "./pages/real-estate/RealEstate";
import Insurance from "./pages/real-estate/Insurance";
import DigitalMarketing from "./pages/real-estate/DigitalMarketing";
import SmallBusiness from "./pages/real-estate/SmallBusiness";
import SalesProfessionals from "./pages/real-estate/SalesProfessionals";
import AugeoRedeem from "./pages/augeo-redeem/augeo-redeem";
import AugeoPaymentPage from "./pages/augeo-redeem/augeo-payment";
import ThankYouAugeo from "./pages/augeo-redeem/augeo-payment-thank-you";
import UpdateSubscription from "./pages/UpdateSubscription";
import TviStarDashboardPart from "./pages/dashboard/tvi-star-dashboard/tvi-star-dashboard";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* <RouteWrapper path="/" component={DashboardPage} layout={Layout} exact/> */}
          <RouteWrapper
            path="/"
            component={DashboardMemberPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/travel-certificates"
            component={TravelCertificates}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/travel-certificate-details/:id"
            component={DownloadTravelCertificate}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/order-checkout-form"
            component={OrderCheckoutStripeCheckOutForm}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/order-payment-redirect"
            component={OrderCheckoutStripeRedirect}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/order-checkout-payment-success"
            component={OrderCheckoutPaymentSuccess}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/order-checkout-payment-failed"
            component={OrderCheckoutPaymentFailed}
            layout={Layout}
            exact
          />
          {/* <RouteWrapper path="/add-link" component={AddLink} layout={Layout} exact/>
          <RouteWrapper path="/add-certificate" component={AddCertificate} layout={Layout} exact/>
          <RouteWrapper path="/user-details" component={UserDetails} layout={Layout} exact/>
          <RouteWrapper path="/transaction-details" component={TransactionDetails} layout={Layout} exact/>   
          <RouteWrapper path="/bulk-upload" component={BulkUploadCertificates} layout={Layout} exact/>    
          
          <RouteWrapper path="/add-member" component={AddMember} layout={Layout} exact/>
          <RouteWrapper path="/bulk-upload-member" component={BulkUploadMember} layout={Layout} exact/>
          <RouteWrapper path="/login" component={LoginPage} layout={Layout} exact/> */}
          {/* <RouteWrapper
            path="/payment/:price"
            component={PaymentPage}
            layout={Layout}
            exact
          /> */}
          <RouteWrapper
            path="/login"
            component={LoginPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/forget-password/:token"
            component={ForgetPassword}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/events"
            component={EventPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/payment/:email/:certiId/:sender_id?/:rec_name?/:source_domain?"
            component={PaymentPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/events"
            component={EventPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/wix-template"
            component={WixTemplate}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/shareable-images"
            component={ShareableImages}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/certificate-guide"
            component={CertificateGuide}
            layout={Layout}
            exact
          />
          <RouteWrapper path="/faqs" component={Faqs} layout={Layout} exact />
          <RouteWrapper
            path="/success"
            component={SuccessPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/failed"
            component={FailedPage}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/logout"
            component={LogoutPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/coming-soon"
            component={ComingSoon}
            layout={Layout}
            exact
          />
          <RouteWrapper path="/print" component={Print} layout={Layout} exact />
          <RouteWrapper
            path="/plan"
            component={PlanPart}
            layout={Layout}
            exact
          />

          {/* <Route path="/affiliate/:code" component={PlanPart} layout={Layout} /> */}

          {/* <Route path="/affiliate/:code" component={AffiliatePlanPart} layout={Layout} /> */}

          {/* <Route
            path="/refer/:code"
            component={AffiliatePlanPart}
            layout={Layout}
            exact
          /> */}
          <Route
            path="/refer/:code"
            component={RefferalPage}
            layout={Layout}
            exact
          />
          <Route
            path="/lead-generate/:code"
            component={LeadGenerateForm}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/check-out"
            component={CheckoutPart}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/order-check-out"
            component={OrderCheckoutPart}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/affiliate-dashboard"
            component={AffiliateDashboard}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/my-account"
            component={MyAccount}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/tvi-redeem"
            component={TviRedeem}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/thank-you"
            component={ThankYou}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/travel-certificate-dashboard"
            component={TravelCertificateDashboard}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/preferred-partner"
            component={PreferredPartnerTab}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/embed-code"
            component={EmbedCodePage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/customer-facing/:sender_id/:certificateId/:source_domain?"
            component={CustomerFacingPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/training-video-dashboard"
            component={TrainingVideo}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/resources-dashboard"
            component={ResourcesDashbaord}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/tvi-star-dashboard"
            component={TviStarDashboardPart}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/tvi-academy"
            component={TviAcademy}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/update-plan"
            component={UpdatePlan}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/update-plan-payment"
            component={UpdatePlanPayment}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/update-subscription"
            component={UpdateSubscription}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/embedded-apps/tvi"
            component={EmbeddedAppsTVI}
            layout={Layout}
            exact
          />

          <Route path="/faq/:code" component={FAQ} layout={Layout} exact />
          <Route
            path="/affiliate/:code"
            component={Affiliate}
            layout={Layout}
            exact
          />
          <Route
            path="/real-estate/:code"
            component={RealEstate}
            layout={Layout}
            exact
          />
          <Route
            path="/insurance/:code"
            component={Insurance}
            layout={Layout}
            exact
          />
          <Route
            path="/digital-marketing/:code"
            component={DigitalMarketing}
            layout={Layout}
            exact
          />
          <Route
            path="/small-business/:code"
            component={SmallBusiness}
            layout={Layout}
            exact
          />
          <Route
            path="/sales-professionals/:code"
            component={SalesProfessionals}
            layout={Layout}
            exact
          />
          <Route
            path="/augeo-certificate-redeem/:email/:certiId/:sender_id?/:rec_name?/:source_domain?"
            component={AugeoRedeem}
            layout={Layout}
            exact
          />
          <Route
            path="/augeo-certificate-redeem-payment/:req"
            component={AugeoPaymentPage}
            layout={Layout}
            exact
          />
          <Route
            path="/augeo-thank-you"
            component={ThankYouAugeo}
            layout={Layout}
            exact
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
